import classNames from 'classnames'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useGlobalDispatchContext, useGlobalStateContext } from '../../hooks/useCtx'

export const Sidebar = () => {
  const { sidebar } = useGlobalStateContext()
  const dispatch = useGlobalDispatchContext()
  const sidebarClass = classNames(
    'sidebar', {
      'sidebar--open': typeof sidebar === 'boolean' && sidebar,
      'sidebar--hidden': sidebar === 'hidden'
    }
  )
  return (
    <div className={sidebarClass}>
      <nav className='nav'>
        <div className='nav__brand' onClick={() => dispatch({ type: 'TOGGLE_MENU' })}>
          <ion-icon
            name='menu-outline'
            className='nav__toggle'
            id='nav-toggle'
          />
          <h2 className='logo'>Digger</h2>
        </div>
        <div className='nav__list'>
          <NavLink to='/app' activeClassName='nav__link--active' exact className='nav__link'>
            <ion-icon name='home-outline' className='nav__icon' />
            <span className='nav__name'>Home</span>
          </NavLink>
          <NavLink to='/chat' activeClassName='nav__link--active' className='nav__link'>
            <ion-icon name='chatbox-ellipses-outline' className='nav__icon' />
            <span className='nav__name'>Chat</span>
          </NavLink>
        </div>

        <NavLink to='/app/profile' exact className='nav__link'>
          <ion-icon name='person-outline' className='nav__icon' />
          <span className='nav__name'>Profile</span>
        </NavLink>
      </nav>
    </div>
  )
}
