import classNames from 'classnames'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Pill } from './Pill'

export const PublicJobCard = ({ job, horizontal }) => {
  function useQuery () {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()

  const cardClass = classNames(
    'job__card',
    {
      'col-xs-6 col-md-4': !horizontal,
      'job__card--horizontal col-xs-12 col-md-6': horizontal
    }
  )

  return (
    <NavLink to={query.get('type') ? `/public/job/${job.uid}?type=${query.get('type')}` : `/public/job/${job.uid}`} className={cardClass}>
      {horizontal && (<img width='20' height='20' className='job__image' src='https://images.unsplash.com/photo-1620571625716-0cf35704a7cc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80' alt='' />)}
      {job.jobCandidates !== undefined && job.jobCandidates.length > 0 && job.jobCandidates[0]?.status !== 'applicant' && job.jobCandidates[0]?.status !== 'shortlist' && (
        <div
          className={
              job.jobCandidates[0].matchScore > 80
                ? 'match__score green'
                : job.jobCandidates[0].matchScore > 70
                  ? 'match__score blue'
                  : job.jobCandidates[0].matchScore > 40
                    ? 'match__score yellow'
                    : 'match__score red'
            }
        >
          {job.jobCandidates[0].matchScore}
        </div>
      )}
      {job.jobCandidates !== undefined && job.jobCandidates.length > 0 && job.jobCandidates[0].status !== 'matched' && (
        job.jobCandidates[0].status === 'applicant'
          ? (<Pill addedClass='job__status' message='applied' />)
          : job.jobCandidates[0].status === 'shortlist'
            ? (<Pill addedClass='job__status' type='Success' message='shortlisted' />)
            : null
      )}
      <div className='job__details'>
        <h3 className='job__title'>{job.title}</h3>
        <div className='location__details'>
          {job.location !== undefined && (<img src={job.location.company?.photoUrl} width='20' height='20' alt='location' className='location__image' />)}
          <p className='location__title'>{job.location?.title}</p>
        </div>
        <div className='job__meta'>
          <p className='job__location'>
            <span className='icon'><ion-icon className='meta__icon' name='location-outline' /></span>
            {job.location.addressDescription}
          </p>
          <p className='job__salary'>
            <span className='icon'><ion-icon className='meta__icon' name='cash-outline' /></span>
            {job.maxSalary}
          </p>
        </div>
      </div>
    </NavLink>
  )
}
