import axios from 'axios'
import dayjs from 'dayjs'
import { auth } from '../firebase'

export const diggerApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

/*
|--------------------------------------------------------------------------
| Private Shared Functions
|--------------------------------------------------------------------------
*/

export const AUTH_HEADER = async () => {
  const idToken = await auth.currentUser.getIdTokenResult()
  if (idToken && dayjs().isBefore(idToken.expirationTime)) {
    return `Bearer ${idToken.token}`
  } else {
    return (
      `Bearer ${await auth.currentUser.getIdToken(true)}`
    )
  }
}

export const UID = async () => {
  const uid = window.localStorage.getItem('uid')
  if (uid) {
    return uid
  } else {
    await auth.currentUser.getIdTokenResult().then(idToken => {
      window.localStorage.setItem('uid', idToken.claims.api.uid)
      return idToken.claims.api.uid
    })
  }
}

/*
|--------------------------------------------------------------------------
| Job Functions
|--------------------------------------------------------------------------
*/
export const fetchJobs = async () => {
  return (
    await axios.get(`${process.env.REACT_APP_API_URL}/jobs`, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    }).then(res => res.data.data)
  )
}

// export const fetchPublicJobs = async () => {
//   return (
//     await axios
//       .get(`${process.env.REACT_APP_API_URL}/jobs`)
//       .then(res => res.data.data)
//   )
// }

export const getSingleJob = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_key, { jobUid }] = queryKey
  return (
    await axios.get(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data)
  )
}

export const applyForJob = async (data) => {
  const uid = await UID()
  const { jobUid, jobRequirementCandidateProof } = data
  return (
    axios.put(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}/candidates/${uid}/status/apply`,
      { jobRequirementCandidateProof: jobRequirementCandidateProof },
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data.data)
  )
}

export const forceApplyForJob = async (data) => {
  const uid = await UID()
  const { jobUid, jobRequirementCandidateProof } = data
  return (
    axios.post(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}/candidates/${uid}`,
      { jobRequirementCandidateProof: jobRequirementCandidateProof },
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data.data)
  )
}

export const interactWithEvent = async (data) => {
  // url:  `${process.env.REACT_APP_API_URL}/jobs/${jobUid}/candidates/${uid}/chat/:uid/event/accepted||rejected`,
  const { jobCandidateUid, uid, type } = data
  return (
    axios.put(
      `${process.env.REACT_APP_API_URL}/jobs/job-candidates/${jobCandidateUid}/chat/${uid}/event/status/${type}`,
      null,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data.data)
  )
}

/*
|--------------------------------------------------------------------------
| User Functions
|--------------------------------------------------------------------------
*/
export const getCurrentUser = async () => {
  let uid
  if (auth.currentUser) {
    await auth.currentUser.getIdTokenResult().then(idToken => {
      uid = idToken.claims.api?.uid
      window.localStorage.setItem('uid', uid)
    })
  }

  if (uid) {
    return (
      await axios.get(`${process.env.REACT_APP_API_URL}/candidates/${uid}`, {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }).then(res => res.data.data)
    )
  }
}

export const createCandidate = async ({ ...data }) => {
  return (
    axios.post(
      `${process.env.REACT_APP_API_URL}/candidates`,
      data
    ).then(async (res) => res.data)
  )
}

export const updateCandidate = async (data) => {
  const uid = await UID()
  return (
    axios.put(
      `${process.env.REACT_APP_API_URL}/candidates/${uid}`,
      data,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then((res) => res.data)
  )
}

export const onboardCandidate = async ({ ...data }) => {
  const uid = await UID()
  return (
    axios.post(
      `${process.env.REACT_APP_API_URL}/candidates/${uid}/onboard`,
      data,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => {
      window.localStorage.setItem('menuStatus', 'open')
      return res.data
    })
  )
}

export const runMatches = async () => {
  const uid = await UID()
  return (
    axios.get(
      `${process.env.REACT_APP_API_URL}/candidates/${uid}/matches`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
  ).then(res => res.data)
}

export const createMedia = async (data) => {
  const uid = await UID()
  return (
    axios.post(
      `${process.env.REACT_APP_API_URL}/candidates/${uid}/media`,
      data,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
  )
}

export const createSkill = async (data) => {
  const uid = await UID()

  return (
    axios.post(
      `${process.env.REACT_APP_API_URL}/candidates/${uid}/skills`,
      data,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
  )
}
export const createManySkills = async (data) => {
  const uid = await UID()
  data.candidateUid = uid

  return (
    axios.post(
      `${process.env.REACT_APP_API_URL}/candidates/${uid}/skills/many`,
      data,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(async () => {
      await runMatches()
    })
  )
}

export const deleteSkill = async (skillUid) => {
  const uid = await UID()
  return (
    axios.delete(
      `${process.env.REACT_APP_API_URL}/candidates/${uid}/skills/${skillUid}`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data)
  )
}

export const deleteMedia = async (mediaUid) => {
  const uid = await UID()
  return (
    axios.delete(
      `${process.env.REACT_APP_API_URL}/candidates/${uid}/media/${mediaUid}`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data)
  )
}

/*
|--------------------------------------------------------------------------
| Location Functions
|--------------------------------------------------------------------------
*/
export const getRelevantLocations = async () => {
  return (
    await axios.get(`${process.env.REACT_APP_API_URL}/locations/for-me`, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    }).then(res => res.data.data)
  )
}

export const fetchLocations = async () => {
  return (
    axios.get(
      `${process.env.REACT_APP_API_URL}/locations`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data.data)
  )
}

/*
|--------------------------------------------------------------------------
| Skill Functions
|--------------------------------------------------------------------------
*/
export const searchSkills = async (name, offset = 0) => {
  // eslint-disable-next-line
  return (
    axios.get(`${process.env.REACT_APP_API_URL}/skills/search?name=${name}&offset=${offset}`, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    }).then(res => res.data.data.skills)
  )
}
