import React from 'react'

// Packages
import { ErrorMessage, Field } from 'formik'

export const Input = ({ onInput, name, className, value, label, placeholder, index, type = 'text', required = false }) => {
  return (
    <div className='input__wrapper'>
      <Field
        className={className && className}
        placeholder={placeholder}
        required={required}
        autoComplete='off'
        label={label}
        name={name}
        value={value}
        type={type}
        onInput={e => onInput && onInput(e.target.value, index)}
      />
      <p className='input__error'><ErrorMessage name={name} /></p>
    </div>
  )
}
