import React, { useContext } from 'react'
import { useQuery } from 'react-query'
// Package Imports
import { Redirect, Route, useHistory } from 'react-router-dom'
import { getCurrentUser } from '../../hooks/useApi'
import { AuthContext } from '../../hooks/useAuth'

export const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const history = useHistory()
  const { currentUser } = useContext(AuthContext)

  useQuery('user', getCurrentUser, {
    enabled: !!currentUser,
    refetchOnMount: false,
    staleTime: 1000 * 60 * 15,
    onSuccess: (data) => {
      if (data?.candidateSkills.length < 1) history.push('/profile/skills')
    }
  })

  let routeAllowed = false
  if (!currentUser) {
    console.log(window.location.pathname)
    if (window.location.pathname.includes('/app/job/')) history.push(`/public/${window.location.pathname.split('/app/')[1]}`)
  } else {
    // USER IS AUTHENTICATED
    routeAllowed = true
  }

  return (
    <Route
      {...rest}
      render={routeProps =>
        routeAllowed
          ? (
            <RouteComponent test='123' {...routeProps} />
            )
          : <Redirect to='/auth/sign-in' />}
    />
  )
}
