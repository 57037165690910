import classNames from 'classnames'
import { AnimatePresence } from 'framer-motion'
import React, { useEffect } from 'react'
import ReactDom from 'react-dom'
import PropTypes from 'prop-types'
import { useKeyDown } from '../../hooks/utils/useKeyDown'
import { Section } from './Section'

export const modalReducer = (state, action) => {
  const bodyNode = document.querySelector('body')
  const { type } = action
  switch (type) {
    case 'open':
      bodyNode.style.overflow = 'hidden'
      bodyNode.style.height = '100vh'
      return {
        ...state,
        open: true
      }
    case 'close':
      bodyNode.style.overflow = 'initial'
      return {
        ...state,
        open: false
      }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

const Modal = ({ children, open, size, onClose, title, caption, centered = false }) => {
  const escPressed = useKeyDown('Escape')

  const modalClasses = classNames(
    'modal',
    {
      'modal--small': size === 'small'
    }
  )
  // eslint-disable-next-line
  useEffect(() => onClose(), [escPressed])

  if (!open) return null
  return ReactDom.createPortal(
    <AnimatePresence>
      <div className={modalClasses} onClick={() => onClose()}>
        <div className='modal__content' onClick={e => e.stopPropagation()}>
          {(title || caption) && (
            <>
              <Section size='small'>
                {title && <Section.Title>{title}</Section.Title>}
                {caption && <Section.Footer>{caption}</Section.Footer>}
              </Section>
              <div className='divider' />
            </>
          )}
          {children}
        </div>
      </div>
    </AnimatePresence>,
    document.getElementById('modal')
  )
}

Modal.propTypes = {
  size: PropTypes.oneOf(['medium', 'small']),
  onClose: PropTypes.func.isRequired,
  centered: PropTypes.bool
}

export { Modal }
