import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { List } from '../../components/blocks/List'
import { Row } from '../../components/layout/Row'
import db from '../../firebase'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/calendar'
import { Form, Formik } from 'formik'
import { Input } from '../../components/blocks/Input'
import { useUser } from '../../hooks/useUser'
import { useMutation } from 'react-query'
import { interactWithEvent } from '../../hooks/useApi'
import { Link } from 'react-router-dom'
import { useJobCandidate } from '../../hooks/useJobs'

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(advancedFormat)

export const ChatOpen = () => {
  // STATE
  const [chatMeta, setChatMeta] = useState(null)
  // eslint-disable-next-line
  const [messages, setMessages] = useState([])
  // eslint-disable-next-line
  const [propose, setPropose] = useState(null)

  // CONSTANTS
  const { chatUid } = useParams()
  const chatRef = useRef(null)
  const messageListRef = useRef(null)

  // API
  const userQuery = useUser()
  const { mutateAsync } = useMutation('eventInteraction', interactWithEvent)
  const jobCandidateQuery = useJobCandidate(chatUid)
  console.log(jobCandidateQuery.data)

  useEffect(() => {
    async function initMessages () {
      const channelDetails = await db.collection('channels').doc(chatUid).get()
      setChatMeta({
        channelId: chatUid,
        channelName: channelDetails.data().name,
        companyPhotoUrl: channelDetails.data().photoUrl
      })
      const unsubscribe = db
        .collection('channels')
        .doc(chatUid)
        .collection('messages')
        .orderBy('createdAt', 'desc')
        .where('visibility', 'array-contains', 'candidate')
        .onSnapshot(snap => {
          setMessages(snap.docs.map(doc => {
            const msgObj = doc.data()
            return {
              ...msgObj,
              uid: doc.id
            }
          }))
        })

      return () => unsubscribe()
    }
    initMessages()
  }, [chatUid])

  useEffect(() => {
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    if (height && chatRef && messageListRef) {
      const offset = messageListRef.current.getBoundingClientRect().top
      const inputHeight = chatRef.current.clientHeight
      messageListRef.current.style.height = `calc(100vh - (${offset}px + ${inputHeight}px + 1.375rem))`
    }
  }, [chatRef, chatMeta])

  const sendMessage = (message) => {
    const messageToSend = db.collection('channels').doc(chatMeta?.channelId ? chatMeta.channelId : chatUid).collection('messages')
    messageToSend
      .add({
        content: {
          // type: docs ? 'doc' : 'text',
          text: message,
          url: ''
        },
        type: 'text',
        photoUrl: userQuery.data.photoUrl,
        senderName: userQuery.data.fullName,
        visibility: ['admin', 'candidate'],
        senderUid: window.localStorage.getItem('uid'),
        createdAt: `${dayjs.utc().format()}`
      })
      .catch(error => {
        console.error(error)
      })
  }

  const respondToEvent = async (type, uid, newTime = null) => {
    const messageToSend = db.collection('channels').doc(chatMeta?.channelId ? chatMeta.channelId : chatUid).collection('messages')
    await mutateAsync({
      jobCandidateUid: chatUid,
      uid,
      type
    })

    if (type === 'accepted') {
      messageToSend
        .add({
          type: 'log',
          createdAt: `${dayjs.utc().format()}`,
          content: {
            text: `${userQuery.data.fullName} accepted the interview request`
          },
          visibility: ['admin']
        })
    } else if (type === 'declined' && !newTime) {
      messageToSend
        .add({
          type: 'log',
          createdAt: `${dayjs.utc().format()}`,
          content: {
            text: `${userQuery.data.fullName} declined the interview request`
          },
          visibility: ['admin']
        })
    } else if (type === 'declined' && newTime) {
      messageToSend
        .add({
          type: 'log',
          createdAt: `${dayjs.utc().format()}`,
          content: {
            text: `${userQuery.data.fullName} has requested ${dayjs(newTime.date).format('D')} ${dayjs(newTime.date).format('MMMM')} at ${newTime.time} for an interview`
          },
          visibility: ['admin']
        })
    }
  }

  return (
    <div className='col-xs-9 chat__open'>
      <div className='chat__header'>
        {chatMeta && (
          <div className='channel channel--noWidth channel--noBorder channel--noMargin'>
            <img width='20' height='20' src={chatMeta.companyPhotoUrl} alt='job cover' className='channel__img' />
            <div className='chat__details'>
              <h3 className='channel__title'>{chatMeta.channelName}</h3>
              <p className='channel__message'><span>Members:</span> Dan Sivewright, Michael Atterbury</p>
            </div>
          </div>
        )}
        <div className='header__actions'>
          {!jobCandidateQuery.isLoading && jobCandidateQuery.data && (
            <Link to={`/app/location/${jobCandidateQuery?.data?.job?.locationUid}`} style={{ marginRight: '1rem' }} className='button button__secondary button--icon'>
              <ion-icon name='location-outline' />
            </Link>
          )}
          {!jobCandidateQuery.isLoading && jobCandidateQuery.data && <Link to={`/app/job/${jobCandidateQuery.data.job.uid}`} className='button button__primary'>View Job</Link>}
        </div>
      </div>
      {messages && (
        <Row>
          <ul ref={messageListRef} className='message__list col-xs-12'>
            {messages.map((msg, index) => {
              const type = msg.type

              switch (type) {
                case 'text':
                  return (
                    <li className={`message message__${type}`} key={index}>
                      <div className='messageContent'>
                        <img width='20' height='20' src={msg?.photoUrl ? msg.photoUrl : 'https://picsum.photos/200/200'} alt='user' className='message__avatar' />
                        <h3 className='messageSender'>
                          {msg.senderName} · <span>{dayjs.utc(msg.createdAt).local().fromNow()}</span>
                        </h3>

                        {(type === 'text' || msg.content.type === 'text') && <p>{msg.content.text}</p>}
                      </div>
                    </li>
                  )
                case 'doc':
                  return (
                    <li className={`message message__${type}`} key={index}>
                      <div className='messageContent'>
                        <img width='20' height='20' src={msg?.photoUrl ? msg.photoUrl : 'https://picsum.photos/200/200'} alt='user' className='message__avatar' />
                        <h3 className='messageSender'>
                          {msg.senderName} · <span>{dayjs.utc(msg.createdAt).local().fromNow()}</span>
                        </h3>
                        <div className='messageDocs'>
                          <div className='docWrapper'>
                            <div className='content'>
                              <div className='platform'>YouTube</div>
                              <div className='title'>{msg.content.text}</div>
                              <div
                                className='video'
                                style={{
                                  backgroundImage: `url(${msg.content?.url})`
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                case 'meeting':
                  return (
                    <li className={`message message__${type}`} key={index}>
                      <div className='messageContent'>
                        <img width='20' height='20' src={msg?.photoUrl ? msg.photoUrl : 'https://picsum.photos/200/200'} alt='user' className='message__avatar' />
                        <h3 className='messageSender'>
                          {msg.senderName} · <span>{dayjs.utc(msg.createdAt).local().fromNow()}</span>
                        </h3>
                        <div className='messageDocs'>
                          <div className='docWrapper'>
                            <div className='content'>
                              <div className='platform'>{msg.content.event.title}:</div>
                              <List>
                                <List.Item icon={<ion-icon name='calendar-outline' />}>
                                  <List.Caption>Date:</List.Caption>
                                  {dayjs(msg.content.event.date).format('DD/MM/YYYY')}
                                </List.Item>
                                <List.Item icon={<ion-icon name='timer-outline' />}>
                                  <List.Caption>Duration:</List.Caption>
                                  {msg.content.event.duration.hours > 0 && `${msg.content.event.duration.hours}hr/s`}
                                  {msg.content.event.duration.minutes > 0 && `${msg.content.event.duration.minutes}mins`}
                                </List.Item>
                              </List>
                              {msg.content.event.status === 'pending' && (
                                <>
                                  <div className='message__actions' style={{ marginBottom: '.5rem' }}>
                                    <button onClick={() => respondToEvent('declined', msg.uid)} className='button button__secondary'>Decline</button>
                                    <button onClick={() => respondToEvent('accepted', msg.uid)} className='button button__primary'>Accept</button>
                                    {/* <Button onClick={() => respondToEvent('declined', msg.uid)} variant='grey'>Decline</Button>
                                    <Button onClick={() => respondToEvent('accepted', msg.uid)}>Accept</Button> */}
                                  </div>
                                  {/* <button onClick={() => openModal(msg.uid)} className='button__link full'>Propose a new time</button> */}
                                  <button className='button button__primary button--full'>Propose a new time</button>
                                </>
                              )}
                              {msg.content.event.status === 'accepted' && (
                                <>
                                  <p>You accepted this interview</p>
                                  <a download={`${msg.content.event.title}.ics`} className='title' href={msg.content.event.attachment}>Add To Calendar</a>
                                </>
                              )}
                              {msg.content.event.status === 'declined' && (
                                <p>You declined this interview</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )

                case 'log':
                  return (
                    <li className={`message message__${type}`} key={index}>
                      {msg.content.text}
                    </li>
                  )
                default:
                  return null
              }
            })}
          </ul>
        </Row>
      )}
      <Formik
        initialValues={{
          message: ''
        }}
        onSubmit={async (values, { resetForm }) => {
          const { message } = values
          // RUN DISPATCH
          if (messages !== '') {
            sendMessage(message)
          }
          resetForm()
        }}
      >
        {({ values, errors, isSubmitting, dirty, isValid }) => (
          <Form className='chat__input' ref={chatRef}>
            <Input value={values.message} name='message' placeholder='Your message' type='text' className='input__field' />
            <div className='chat__actions'>
              {/* <button type='button' className='button button__secondary'><ion-icon name='attach-outline' /></button> */}
              <button type='submit' className='button button__primary'>Send</button>
            </div>
          </Form>
        )}
      </Formik>

    </div>
  )
}
