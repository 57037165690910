import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Section = ({ children, size = 'default', style, ...rest }) => {
  const sectionClass = classNames(
    'section',
    {
      'section--small': size === 'small'
    }
  )

  return (
    <div style={{ ...style }} className={sectionClass}>
      {children}
    </div>
  )
}

Section.Title = ({ children, style, ...rest }) => {
  return <h2 style={{ ...style }} className='section__title'>{children}</h2>
}

Section.Footer = ({ children, ...rest }) => {
  return <p className='section__footer'>{children}</p>
}

Section.propTypes = {
  size: PropTypes.oneOf(['default', 'small'])
}
export { Section }
