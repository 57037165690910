import React, { createContext, useEffect, useState } from 'react'
import { Loader } from '../components/blocks/Loader'
import { auth } from '../firebase'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [pending, setPending] = useState(true)

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setPending(false)
      if (user) {
        auth.currentUser.getIdTokenResult().then(idToken => {
          if (Object.prototype.hasOwnProperty.call(idToken.claims, 'api')) {
            if (idToken.claims?.api?.type !== 'candidate') {
              auth.signOut()
            } else if (typeof window.webkit !== 'undefined' && idToken.claims.api.uid) {
              auth.currentUser.getIdToken().then(authToken => {
                window.webkit.messageHandlers.logIn.postMessage(JSON.stringify({ uid: idToken.claims.api.uid, token: authToken }))
              })
            }
          }
        }).catch(() => {
          auth.signOut()
        })
      } else {
        window.localStorage.clear()
        if (typeof window.webkit !== 'undefined') {
          window.webkit.messageHandlers.logOut.postMessage('logout')
        }
      }
    })
    // eslint-disable-next-line
  }, [])

  if (pending) {
    return (
      <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loader />
      </div>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
