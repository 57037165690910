import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Input } from '../../components/blocks/Input'
import { Loader } from '../../components/blocks/Loader'
import * as Yup from 'yup'
import { useMutation } from 'react-query'
import { createCandidate } from '../../hooks/useApi'
import { auth } from '../../firebase'
import { useAlert } from '../../hooks/useAlert'
import { Alert } from '../../components/blocks/Alert'
import { Link, useHistory } from 'react-router-dom'
import { useGlobalDispatchContext } from '../../hooks/useCtx'

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Please enter your full name').min(3, 'Too short'),
  email: Yup.string().email('Please enter a valid email').required('Email is required'),
  password: Yup.string().required('Please enter a password').min(8, 'Password is too short - should be at least 8 characters')
})

export const Register = () => {
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line

  // CONSTANTS
  const dispatch = useGlobalDispatchContext()
  const { push } = useHistory()
  const { isActive, message, openAlert } = useAlert()

  // API
  const { mutateAsync: registerCandidate, isLoading } = useMutation(createCandidate, {
    onSuccess: async () => {
      await auth.currentUser.getIdTokenResult(true).then(idToken => {
        window.localStorage.setItem('uid', idToken.claims.api.uid)
        dispatch({ type: 'RESET' })
        push('/auth/onboarding')
      })
    },
    onError: (error) => openAlert(error.message)
  })

  return (
    <div className='auth__page'>
      <Alert isActive={isActive} message={message} />
      <div className='auth__content'>
        <h1 className='auth__title'>Register</h1>
        <p className='auth__message'>Welcome to digger, where jobs find you</p>
        <Formik
          initialValues={{
            fullName: '',
            email: '',
            password: '',
            remember: false
          }}
          validationSchema={validationSchema}
          onSubmit={(data) => {
            setLoading(true)

            auth
              .createUserWithEmailAndPassword(data.email, data.password)
              .then((res) => {
                registerCandidate({
                  fullName: data.fullName,
                  email: data.email,
                  authUid: res.user.uid,
                  photoUrl: 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/ec6b4184897953.5d6d1f6254960.jpg'
                })
              })
              .catch(error => {
                setLoading(false)
                openAlert(error.message)
              })
          }}
        >
          {({ values, errors, isSubmitting, dirty, isValid }) => (
            <Form className='auth__card'>
              <Input placeholder='Full name' className='above' type='text' name='fullName' />
              <Input placeholder='Your Email' className='above' type='email' name='email' />
              <Input placeholder='Enter Password' className='above' type='password' name='password' />
              <div className='auth__extras'>
                <label className='checkbox__inline'><input type='checkbox' name='remember' />Remember Me</label>
                {/* <p className='forgot'>Forgot Password?</p> */}
              </div>
              <button type='submit' className='button button__primary button--full'>
                {isLoading || loading ? <Loader /> : 'Register'}
              </button>
            </Form>
          )}
        </Formik>
        <Link to='/auth/sign-in' className='auth__caption'>Already have a digger account?</Link>
      </div>
    </div>
  )
}
