import { Field, Form, Formik } from 'formik'
import React, { useEffect, useReducer, useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { Alert } from '../../components/blocks/Alert'
import { Grid } from '../../components/blocks/Grid'
import { Loader } from '../../components/blocks/Loader'
import { Editor } from '../../components/layout/Editor'
import { Section } from '../../components/layout/Section'
import { useAlert } from '../../hooks/useAlert'
import { updateCandidate } from '../../hooks/useApi'
import { useUser } from '../../hooks/useUser'
import { useUploader } from '../../hooks/utils/useUploader'

const types = ['image/png', 'image/jpeg', 'image/jpg']

const editReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'initial':
      console.log(payload)
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          seeking: payload.isJobSeeking ? 'yes' : 'no',
          mobile: payload.mobile && payload.mobile,
          gender: payload.gender && payload.gender,
          race: payload.race && payload.race,
          location: payload.canRelocate ? 'anywhere' : 'near',
          remote: payload.remoteWork && 'yes',
          educationLevel: payload.educationLevel
        },
        biographyAsDante: payload.biographyAsDante,
        photoUrl: payload.photoUrl,
        addressString: payload.addressDescription ? payload.addressDescription : '',
        status: 'initialized'
      }
    case 'location':
      return {
        ...state,
        addressString: payload
      }
    case 'select-location':
      return {
        ...state,
        location: payload
      }
    case 'upload-file':
      if (payload) {
        if (types.includes(payload.type)) {
          return {
            ...state,
            file: payload,
            loading: true
          }
        } else {
          return {
            ...state,
            error: 'Please select an image file (png or jpg)'
          }
        }
      } else return { ...state }
    case 'url':
      return {
        ...state,
        photoUrl: payload,
        loading: false
      }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const ProfileEdit = () => {
  // STATE
  const [state, dispatch] = useReducer(editReducer, {
    initialValues: {
      seeking: '',
      mobile: '',
      gender: '',
      race: '',
      location: '',
      remote: '',
      educationLevel: ''
    },
    biographyAsDante: null,
    file: null,
    photoUrl: null,
    status: 'uninitialized',
    addressString: '',
    location: null,
    error: null,
    loading: false
  })
  const [editor, setEditor] = useState(null)

  // CONSTANTS
  const { progress, url } = useUploader(state.file)
  const { push } = useHistory()
  const { isActive, message, openAlert } = useAlert()
  const queryClient = useQueryClient()

  // API
  const userQuery = useUser()
  const { mutateAsync: update, isLoading } = useMutation(
    updateCandidate, {
      onSuccess: () => {
        push('/app/profile')
        queryClient.invalidateQueries('user')
      },
      onError: (error) => openAlert(error.message)
    })

  // EFFECTS
  useEffect(() => {
    if (!userQuery.isLoading && userQuery.data) dispatch({ type: 'initial', payload: userQuery.data })
    // eslint-disable-next-line
  }, [userQuery.isLoading, userQuery.data])

  useEffect(() => {
    if (url) dispatch({ type: 'url', payload: url })
  }, [url])

  useEffect(() => {
    if (state.error) openAlert(state.error)
    // eslint-disable-next-line
  }, [state.error])

  useEffect(() => {
    console.log(editor)
  }, [editor])

  // METHODS
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value)
    const latLng = await getLatLng(results[0])
    dispatch({ type: 'location', payload: value })
    dispatch({
      type: 'select-location',
      payload: {
        addressString: value,
        point: {
          type: 'Point',
          coordinates: [latLng.lat, latLng.lng]
        }
      }
    })
  }

  return (
    <>
      <Alert isActive={isActive} message={message} />
      {!userQuery.isLoading && userQuery.data && state.status !== 'uninitialized'
        ? (
          <div className='col-xs-12 col-sm-4 profile__edit'>
            <Formik
              initialValues={state.initialValues}
              onSubmit={async (data) => {
                const editorState = await editor.save().then(data => data)

                const profileToUpdate = {
                  ...data,
                  canRelocate: data.location === 'anywhere',
                  isJobSeeking: data.seeking === 'yes',
                  remoteWork: data.remote === true,
                  addressDescription: state.addressString,
                  address: state.location && state.location.point,
                  biographyAsDante: editorState,
                  photoUrl: state.photoUrl
                }
                await update(profileToUpdate)
              }}
            >
              <Form style={{ position: 'relative' }}>
                <Section style={{ marginBottom: '1rem' }} size='small'>
                  <Section.Title>Work Status</Section.Title>
                  <Section.Footer>Letting people know you're looking might increase your chances of being hired</Section.Footer>
                </Section>
                <Grid gap={1}>
                  <label className='layout__button'>
                    <Field name='seeking' id='seeking_yes' value='yes' className='radio' type='radio' />
                    <div className='layoutButton__icon'><ion-icon name='search-outline' /></div>
                    <div className='layoutButton__content'>
                      <h4 className='layoutButton__title'>Looking</h4>
                      <p className='layoutButton__caption smaller'>Let them know you mean business</p>
                    </div>
                  </label>
                  <label className='layout__button'>
                    <Field name='seeking' id='seeking_no' value='no' className='radio' type='radio' />
                    <div className='layoutButton__icon'><ion-icon name='hourglass-outline' /></div>
                    <div className='layoutButton__content'>
                      <h4 className='layoutButton__title'>Not Looking</h4>
                      <p className='layoutButton__caption smaller'>Play the field a little bit. No pressure</p>
                    </div>
                  </label>
                </Grid>
                <Section style={{ margin: '1rem 0' }} size='small'>
                  <Section.Title>Current Address</Section.Title>
                  <Section.Footer>This is used to help finds jobs closer to you</Section.Footer>
                </Section>

                <PlacesAutocomplete
                  value={state.addressString}
                  onSelect={handleSelect}
                  onChange={(e) => dispatch({ type: 'location', payload: e })}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div style={{ position: 'relative' }}>
                      <input {...getInputProps({ placeholder: 'Type Address' })} />
                      {suggestions.length > 0 && (
                        <div className='address-dropdown'>
                          {loading ? <Loader /> : null}
                          {suggestions.map((suggestion, i) => {
                            const className = suggestion.active ? 'address-dropdown__item address-dropdown__item--active' : 'address-dropdown__item'
                            return (
                              <div
                                key={i}
                                {...getSuggestionItemProps(suggestion, {
                                  className
                                })}
                              >
                                <p className='dropdown__icon'><ion-icon name='location-outline' /></p>
                                <p className='address-dropdown__item__address'>{suggestion.formattedSuggestion.mainText} <span>{suggestion.formattedSuggestion.secondaryText}</span></p>
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  )}
                </PlacesAutocomplete>

                <Section style={{ margin: '1rem 0' }} size='small'>
                  <Section.Title>Willing to move?</Section.Title>
                  <Section.Footer>Are you willing to relocate for a job</Section.Footer>
                </Section>
                <label className='layout__button'>
                  <Field name='location' id='location_no' value='near' className='radio' type='radio' />
                  <div className='layoutButton__icon'><ion-icon name='thumbs-down-outline' /></div>
                  <div className='layoutButton__content'>
                    <h4 className='layoutButton__title'>No</h4>
                    <p className='layoutButton__caption smaller'>Show me jobs that are near me</p>
                  </div>
                </label>
                <label className='layout__button'>
                  <Field name='location' id='location_yes' value='anywhere' className='radio' type='radio' />
                  <div className='layoutButton__icon'><ion-icon name='thumbs-up-outline' /></div>
                  <div className='layoutButton__content'>
                    <h4 className='layoutButton__title'>Yes</h4>
                    <p className='layoutButton__caption smaller'>Show me jobs regardless of location</p>
                  </div>
                </label>

                <Section style={{ margin: '1rem 0' }} size='small'>
                  <Section.Title>Work Remotely</Section.Title>
                  <Section.Footer>Or just looking to get out the office</Section.Footer>
                </Section>
                <label className='layout__button'>
                  <Field name='remote' id='remote_yes' className='radio' type='checkbox' />
                  <div className='layoutButton__icon'><ion-icon name='cafe-outline' /></div>
                  <div className='layoutButton__content'>
                    <h4 className='layoutButton__title'>Remote</h4>
                    <p className='layoutButton__caption smaller'>I want to work from home (or a coffee shop)</p>
                  </div>
                </label>
                <label className='label'>Mobile Number</label>
                <Field className='above' name='mobile' type='tel' id='mobile' placeholder='Phone Number' />

                <label className='label'>Gender</label>
                <Field className='above' name='gender' id='gender' placeholder='Enter your gender' />

                <label className='label'>Race</label>
                <Field className='above' name='race' as='select' id='race'>
                  <option value={null}>Select a race...</option>
                  <option value='black'>Black / African</option>
                  <option value='coloured'>Coloured</option>
                  <option value='white'>White</option>
                  <option value='asian'>Asian</option>
                  <option value='indian'>Indian</option>
                  <option value='indian'>Other</option>
                </Field>

                <label className='label'>Education Level</label>
                <Field className='above' name='educationLevel' as='select' id='educationLevel' placeholder='Education Level'>
                  <option value={null}>Select an education level</option>
                  <option value='certificate'>Certificate</option>
                  <option value='course'>Course</option>
                  <option value='diploma'>Diploma</option>
                  <option value='higher_certificate'>Higher Certificate</option>
                  <option value='degree'>Degree</option>
                  <option value='honourary_degree'>Honourary Degree</option>
                  <option value='honours'>Honours</option>
                  <option value='masters'>Masters</option>
                  <option value='doctorate'>Doctorate</option>
                </Field>
                <Section style={{ margin: '1rem 0' }} size='small'>
                  <Section.Title>Biography</Section.Title>
                </Section>
                <Editor initial={state.biographyAsDante} setInstance={setEditor} />
                <Section style={{ margin: '1rem 0' }} size='small'>
                  <Section.Title>Profile Picture</Section.Title>
                </Section>
                <div className='edit__avatar'>
                  <div className='profile__avatar' style={{ backgroundImage: `url(${state.photoUrl})` }}>
                    {!state.photoUrl && <ion-icon name='camera-outline' />}
                  </div>
                  <label className='document__upload'>
                    <div className={!state.loading ? 'button button__primary' : 'button button__primary button--disabled'}>
                      {state.loading ? <Loader /> : <ion-icon name='image-outline' />}
                    </div>
                    {state.loading
                      ? <p>Uploading: {progress}%</p>
                      : state.photoUrl
                        ? <p>Change Image</p>
                        : <p>Click to upload</p>}
                    <input type='file' style={{ display: 'none' }} onChange={e => dispatch({ type: 'upload-file', payload: e.currentTarget.files[0] })} />
                  </label>
                </div>
                <button disabled={state.loading || isLoading} type='submit' className='button button__primary button--full button--above'>
                  {/* {state.loading || isLoading ? <Loader /> : 'Submit'} */}
                  {state.loading
                    ? 'Uploading...'
                    : isLoading
                      ? <Loader />
                      : 'Update'}
                </button>
              </Form>
            </Formik>
          </div>
          )
        : <Loader />}
    </>
  )
}
