import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router'
import { Grid } from '../../components/blocks/Grid'
import { List } from '../../components/blocks/List'
import { Section } from '../../components/layout/Section'
import { useLocation } from '../../hooks/useLocations'
import { setEdjs } from '../../hooks/utils/useEdjs'

export const LocationDetail = () => {
  // STATE
  const [expanded, setExpanded] = useState(false)

  // METHODS
  function useQuery () {
    return new URLSearchParams(useLocation().search)
  }

  // CONSTANTS
  const { locationUid } = useParams()
  const history = useHistory()
  const query = useQuery()

  console.log(window.location.pathname.split('/location')[0])

  // API
  const location = useLocation(locationUid)
  console.log(location)
  return (
    <>
      {!location.isLoading && location.data
        ? (
          <div className='col-xs-12 col-sm-4 detail__view'>
            <div
              className='hero'
              style={{ backgroundImage: `url(${location.data.photoUrl})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}
            >
              <div className='hero__content'>
                <h3 className='hero__title'>{location.data.title}</h3>
                <p className='hero__caption'>{location.data.addressDescription}</p>
              </div>
            </div>
            <div className='page__details'>
              {location.data.jobs.length > 0 && (
                <List>
                  <Grid>
                    <List.Item variant='white noMargin'>
                      <List.Caption>Total Jobs:</List.Caption>
                      {location.data.jobs.length}
                    </List.Item>
                  </Grid>
                </List>
              )}
              <Section style={{ marginTop: '1rem' }}>
                <Section.Title>Description</Section.Title>
              </Section>
              <motion.div
                initial={{ height: '16vh', overflow: 'hidden' }}
                animate={expanded ? { height: 'auto', overflow: 'auto' } : { height: '16vh', overflow: 'hidden' }}
                transition={{ duration: 0.3, ease: [0.6, 0.05, -0.01, 0.9] }}
                className={!expanded ? 'description' : 'description expanded'}
              >
                {/* <div dangerouslySetInnerHTML={{ __html: editorRenderer.parse(location.data.descriptionAsJSON) }} /> */}
                <div className='editor' dangerouslySetInnerHTML={{ __html: setEdjs(location.data.descriptionAsJSON.blocks) }} />
              </motion.div>
              <p onClick={() => setExpanded(!expanded)} className='read__more'>{!expanded ? 'Read More' : 'Show Less'}</p>
              {location.data?.jobs.length > 0 && (
                <>
                  <p className='section__title'>Jobs</p>
                  <List variant='withPadding'>
                    {location.data.jobs.map(job => {
                      if (job.title !== undefined || job.title !== null) return <List.Item onClick={() => query.get('type') ? history.go(`${window.location.pathname.split('/location')[0]}/job/${job.uid}?type=${query.get('type')}`) : history.push(`${window.location.pathname.split('/location')[0]}/job/${job.uid}`)} key={job.uid}>{job.title}</List.Item>
                      else return null
                    })}
                  </List>
                </>
              )}
            </div>
          </div>
          )
        : !location.isLoading && !location.data
            ? (
              <Redirect to='/app' />
              )
            : <div>Loading...</div>}
    </>

  )
}
