import React from 'react'

// PACKAGES
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { PrivateRoute } from './components/helpers/PrivateRoute'
import { Row } from './components/layout/Row'

// COMPONENTS
import { Sidebar } from './components/layout/Sidebar'
import { Login } from './views/auth/Login'
import { Onboarding } from './views/auth/Onboarding'
import { Register } from './views/auth/Register'
import { Chat } from './views/chat/Chat'
import { Jobs } from './views/jobs/Jobs'
import { Proof } from './views/proof/Proof'
import { PublicJobs } from './views/public/PublicJobs'

function RouteWithSubRoutes (route) {
  return (
    <>
      {route.private
        ? (
          <PrivateRoute
            path={route.path}
            routes={route.routes}
            {...route}
          />
          )
        : (
          <Route
            path={route.path}
            render={props => {
              return React.cloneElement(route.component, { ...props })
            }}
          />
          )}
    </>
  )
}

export const App = () => {
  const routes = [
    {
      path: '/app',
      private: true,
      component: () =>
        <Row>
          <Jobs />
        </Row>
    },
    {
      path: '/chat',
      component: () => <Row><Chat /></Row>,
      private: true
    }
  ]

  return (
    <Router>
      <Sidebar />
      <div id='app' className='app'>
        <Switch>
          <Route exact path='/auth/sign-in' component={Login} />
          <Route exact path='/auth/register' component={Register} />
          <PrivateRoute exact path='/auth/onboarding' component={Onboarding} />
          <PrivateRoute exact path='/proof/:jobUid' component={Proof} />
          <Route path='/public' component={PublicJobs} />

          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
          <Route exact path='/'>
            <Redirect to='/app' />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}
