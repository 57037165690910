import React from 'react'
import { render } from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { App } from './App'
import './styles/App.scss'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './hooks/useAuth'
import { GlobalProvider } from './hooks/useCtx'

const queryClient = new QueryClient()

render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <GlobalProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </GlobalProvider>
    </AuthProvider>
  </QueryClientProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
