import { AUTH_HEADER } from './useApi'
import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'

export const useLocations = () => {
  return useQuery(
    'locations',
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/locations`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 30
    }
  )
}

export const useLocation = (locationUid) => {
  const queryClient = useQueryClient()

  return useQuery(
    ['location', locationUid],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/locations/${locationUid}`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data.data), {
      initialData: () => {
        const cachedLocations = queryClient.getQueryData('locations')
        if (cachedLocations !== undefined && Array.isArray(cachedLocations)) return cachedLocations?.find(x => x.uid === locationUid)
        else if (cachedLocations !== undefined && typeof cachedLocations === 'object') return cachedLocations
      },
      initialStale: true,
      enabled: !!locationUid
    }
  )
}
