import React from 'react'

export const Grid = ({ children, rows, columns, style, gap, ...restProps }) => {
  if (!columns) columns = 2
  return (
    <div
      className='grid__wrapper'
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, auto)`,
        gridGap: `${gap}rem`,
        ...style
      }}
    >
      {children}
    </div>
  )
}
