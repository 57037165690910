import classNames from 'classnames'
import React from 'react'

export const Row = ({ children, className, style, align = null, noGap = null, justify = null }) => {
  const rowClasses = classNames(
    'row',
    {
      'row--noGap': noGap,
      [className]: className
    }
  )
  return (
    <section style={{ ...style }} className={rowClasses}>
      {children}
    </section>
  )
}
