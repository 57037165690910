import classNames from 'classnames'
import React from 'react'

export const Pill = ({ type, message, addedClass }) => {
  const msgToUpper = message
  const newMessage = msgToUpper.charAt(0).toUpperCase() + msgToUpper.slice(1)

  const pillClass = classNames(
    'statusPill', {
      [`statusPill--${type}`]: type,
      [addedClass]: addedClass
    }
  )

  return (
    <>
      <div className={pillClass}>
        {newMessage}
      </div>
    </>

  )
}
