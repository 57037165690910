import { motion } from 'framer-motion'
import React, { useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '../../components/blocks/Grid'
import { List } from '../../components/blocks/List'
import { Modal, modalReducer } from '../../components/layout/Modal'
import { Section } from '../../components/layout/Section'
import { usePublicJob } from '../../hooks/useJobs'
import { edjsHTML } from '../../hooks/utils/useHtmlParser'

export const PublicJobDetails = ({ openModal }) => {
  // CONSTANTS
  const [expanded, setExpanded] = useState(false)
  const { jobUid } = useParams()
  const edjsRenderer = edjsHTML()

  // STATE
  const [state, dispatch] = useReducer(modalReducer, { open: false })

  // API
  const jobQuery = usePublicJob(jobUid)
  console.log(jobQuery)

  // REACTIONS
  useEffect(() => {
    if (!jobQuery.isLoading && jobQuery.data) {
      dispatch({ type: 'open' })
    }
  }, [jobQuery.isLoading, jobQuery.data])

  return (
    <Modal
      open={state.open}
      onClose={() => dispatch({ type: 'close' })}
      size='small'
    >
      <div className='detail__view'>
        {!jobQuery.isLoading && jobQuery.data
          ? (
            <div
              className='col-xs-12 col-sm-4 detail__view'
            >
              <div
                className='hero'
                style={{ background: `url(${jobQuery.data.photoUrl})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}
              >
                <div className='hero__content'>
                  <div id='scrollContent'>
                    <h3 className='hero__title job'>{jobQuery.data.title}</h3>
                    <p className='hero__caption'>{jobQuery.data.location.title}</p>
                  </div>
                </div>
                <button id='actionButton' className='hero__floatingBtn button button__primary'>Apply</button>
              </div>
              <div className='page__details'>
                <List>
                  <Grid>
                    <List.Item color='darkblue' variant='white noMargin'>
                      <List.Caption>Max Salary:</List.Caption>
                      R {jobQuery.data.maxSalary}.00
                    </List.Item>
                    <List.Item color='yellow' variant='white'>
                      <List.Caption>Type:</List.Caption>
                      Remote
                    </List.Item>
                  </Grid>
                </List>
                <p className='section__title'>Job Description</p>
                <motion.div
                  initial={{ height: '16vh', overflow: 'hidden' }}
                  animate={expanded ? { height: 'auto', overflow: 'auto' } : { height: '16vh', overflow: 'hidden' }}
                  transition={{ duration: 0.3, ease: [0.6, 0.05, -0.01, 0.9] }}
                  className={!expanded ? 'description' : 'description expanded'}
                >
                  <div dangerouslySetInnerHTML={{ __html: edjsRenderer.parse(jobQuery.data.descriptionAsJSON) }} />
                </motion.div>
                <p onClick={() => setExpanded(!expanded)} className='read__more'>{!expanded ? 'Read More' : 'Show Less'}</p>
                <div className='section__title'>Skills</div>
                <List>
                  {jobQuery.data.jobSkills.map((skill, i) => (
                    <List.Item key={i} variant='border'>
                      <List.Caption>{skill.experience} years experience</List.Caption>
                      {skill.skill.name}
                    </List.Item>
                  ))}
                </List>
                <Section>
                  <Section.Title>Requirements</Section.Title>
                  <Section.Footer>Make sure you have these before you Apply</Section.Footer>
                </Section>
                <List>
                  <List.Item>
                    <List.Caption>Hidden</List.Caption>
                    Register for digger
                  </List.Item>
                </List>
              </div>
            </div>
            )
          : (
            <div>No job</div>
            )}
      </div>
    </Modal>
  )
}
