import firebase from 'firebase/app'
import 'firebase/auth' // for authentication
import 'firebase/storage' // for storage
import 'firebase/firestore'
import 'firebase/analytics'

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyCF5krburHH63ROqOc4tiM9k8RCy-fw8zg',
  authDomain: 'diggerapp-api.firebaseapp.com',
  projectId: 'diggerapp-api',
  storageBucket: 'diggerapp-api.appspot.com',
  messagingSenderId: '574010983407',
  appId: '1:574010983407:web:d575d09e198383b98e2c1a',
  measurementId: 'G-J338BYW9Z4'
})

const analyticsApp = firebase.initializeApp({
  apiKey: 'AIzaSyCl4Et-4FzXkmuh2e-WXBM8JuNQ3dG_vBw',
  authDomain: 'diggerapp-clients.firebaseapp.com',
  databaseURL: 'https://diggerapp-clients.firebaseio.com',
  projectId: 'diggerapp-clients',
  storageBucket: 'diggerapp-clients.appspot.com',
  messagingSenderId: '295948794856',
  appId: '1:295948794856:web:c31003a091793fba82025d',
  measurementId: 'G-H2F4S79X1Z'
}, 'analytics')

const db = firebaseApp.firestore()
const auth = firebase.auth(firebase.apps[0])
const storage = firebase.storage(firebase.apps[0])
const provider = {}

let analytics
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_API_URL.includes('api-main')) {
  analytics = analyticsApp.analytics()
}

export { auth, provider, storage, analytics }
export default db
