import React from 'react'

export const LayoutButton = ({ children, location, onClick, ...restProps }) => {
  return <div {...restProps} onClick={onClick && onClick} className='layout__button'>{children}</div>
}

LayoutButton.Icon = function LayoutButtonIcon ({ children, ...restProps }) {
  return <div {...restProps} className='layoutButton__icon'>{children}</div>
}

LayoutButton.Content = function LayoutButtonContent ({ children, ...restProps }) {
  return <div {...restProps} className='layoutButton__content'>{children}</div>
}

LayoutButton.Title = function LayoutButtonTitle ({ children, ...restProps }) {
  return <h4 {...restProps} className='layoutButton__title'>{children}</h4>
}
LayoutButton.Caption = function LayoutButtonCaption ({ children, ...restProps }) {
  return <p {...restProps} className='layoutButton__caption'>{children}</p>
}
