import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import db from '../../firebase'
import { Pill } from './Pill'

export const Channel = ({ channel }) => {
  const [latestMessage, setLatestMessage] = useState(null)

  useEffect(() => {
    db
      .collection('channels')
      .doc(channel.id)
      .collection('messages')
      .where('visibility', 'array-contains', 'candidate')
      .orderBy('createdAt', 'desc')
      .limit(1)
      .get()
      .then(snap => {
        snap.forEach(doc => setLatestMessage({
          data: {
            message: doc.data().content.text,
            senderName: doc.data().senderName
          },
          createdAt: doc.data().createdAt
        }))
      })
  }, [channel.id])

  return (
    <Link
      to={`/chat/${channel.id}`}
      // to={{
      //   pathname: `/chat/${channel.id}`,
      //   state: {
      //     channelId: channel.id,
      //     channelName: channel.data.name,
      //     companyPhotoUrl: channel.data.photoUrl
      //   }
      // }}
      className='channel'
    >
      <div className='channel__status'><Pill message='applicant' /></div>
      <img width='20' height='20' src={channel.data.photoUrl} alt='chat cover' className='channel__img' />
      <div className='chat__details'>
        <p className='channel__lastSentName'>{latestMessage?.data?.senderName}</p>
        <h3 className='channel__title'>{channel.data.name}</h3>
        {latestMessage && (
          <p className='channel__message'>{latestMessage.data ? latestMessage.data.message : 'No messages yet'}</p>
        )}
      </div>
    </Link>
  )
}
