import { motion } from 'framer-motion'
import React from 'react'

export const List = ({ size, variant, children, ...restProps }) => {
  return (
    <div className={`list__wrapper ${size} ${variant}`}>
      {children}
    </div>
  )
}

List.Item = function ListItem ({ color, uid, onDelete, icon, children, type, variant, drag, onClick, ...restProps }) {
  return (
    <div style={{ position: 'relative' }}>
      <motion.div
        onClick={onClick && onClick}
        drag={drag ? 'x' : false}
        dragConstraints={{ left: -100, right: 0 }}
        className={`list__item ${variant}`}
      >
        {icon
          ? <div className={`item__icon ${type}`}>{icon}</div>
          : <div className={`item__accent ${color}`} />}
        <div className='item__content'>{children}</div>

      </motion.div>
    </div>
  )
}

List.Caption = function ListItem ({ children, ...restProps }) {
  return <div className='list__caption'>{children}</div>
}
