import React, { useMemo, useState } from 'react'
import { Switch, useLocation } from 'react-router-dom'
import { PrivateRoute } from '../../components/helpers/PrivateRoute'
import { Section } from '../../components/layout/Section'
import { Tabs } from '../../components/layout/Tabs'
import { Row } from '../../components/layout/Row'
import { JobDetail } from './JobDetail'
import { useJobs } from '../../hooks/useJobs'
import { filterJobs } from '../../hooks/useMemos'
import { useLocations } from '../../hooks/useLocations'
import { LocationDetail } from '../locations/LocationDetail'
import { Profile } from '../profile/Profile'
import classNames from 'classnames'
import { JobCard } from '../../components/blocks/JobCard'
import { LocationCard } from '../../components/blocks/LocationCard'
import { ProfileEdit } from '../profile/ProfileEdit'

export const Jobs = () => {
  function useQuery () {
    return new URLSearchParams(useLocation().search)
  }

  // CONSTANTS
  const query = useQuery()
  const location = useLocation()

  // STATE
  const [active, setActive] = useState(() => query.get('type'))

  // API
  const jobsQuery = useJobs()
  const locationsQuery = useLocations()
  const jobs = useMemo(() => filterJobs(jobsQuery.data, jobsQuery.isLoading), [jobsQuery.data, jobsQuery.isLoading])

  const jobClasses = classNames(
    'col-xs-12 job__view',
    {
      'col-sm-8': location.pathname.split('/app/')[1],
      'col-sm-12': location.pathname.split('/app/')[1] === false
    }
  )

  return (
    <>
      {
        !jobsQuery.isLoading && !locationsQuery.isLoading
          ? (
            <div className={jobClasses}>
              <Section>
                <Section.Title>Jobs</Section.Title>
                <Section.Footer>Cool things</Section.Footer>
              </Section>
              <Tabs
                name='type'
                onTabChange={setActive}
                active={active}
              >
                <Tabs.Tab value='top'>
                  {jobs.topJobs.length > 0
                    ? (
                      <Row style={{ paddingBottom: '1.375rem' }}>
                        {jobs.topJobs.map(job => (
                          <JobCard key={job.uid} job={job} />
                        ))}
                      </Row>
                      )
                    : (
                      <div>No top jobs</div>
                      )}
                </Tabs.Tab>
                <Tabs.Tab value='recent'>
                  {jobs.recentJobs.length > 0
                    ? (
                      <Row style={{ paddingBottom: '1.375rem' }}>
                        {jobs.recentJobs.map(job => (
                          <JobCard key={job.uid} job={job} />
                        ))}
                      </Row>
                      )
                    : (
                      <div>No recent jobs</div>
                      )}
                </Tabs.Tab>
              </Tabs>

              <Section>
                <Section.Title>Locations</Section.Title>
                <Section.Footer>Learn more about where you could be working</Section.Footer>
              </Section>

              {/* <Row> */}
              <div className='locations__list locations__list--horizontal'>
                {locationsQuery.data.length > 0 && (
                  locationsQuery.data.map(location => (
                    <LocationCard key={location.uid} location={location} />
                  ))
                )}
              </div>

              {jobs.moreJobs.length > 0 && (
                <>
                  <Section style={{ marginTop: '1rem' }}>
                    <Section.Title>More Jobs</Section.Title>
                  </Section>
                  <Row>
                    {jobs.moreJobs.map(job => (
                      <JobCard horizontal key={job.uid} job={job} />
                    ))}
                  </Row>
                </>
              )}

              {/* </Row> */}
            </div>
            )
          : (
            <div>Loading...</div>
            )
          }
      <Switch>
        <PrivateRoute path='/app/job/:jobUid' component={JobDetail} />
        <PrivateRoute path='/app/location/:locationUid' component={LocationDetail} />
        <PrivateRoute exact path='/app/profile' component={Profile} />
        <PrivateRoute exact path='/app/profile/edit' component={ProfileEdit} />
      </Switch>
    </>
  )
}
