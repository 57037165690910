import { getCurrentUser } from './useApi'
import { useQuery } from 'react-query'

export const useUser = () => {
  return useQuery(
    'user',
    getCurrentUser,
    {
      staleTime: 1000 * 60 * 30
    }
  )
}
