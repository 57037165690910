import dayjs from 'dayjs'

export const filterJobs = (arr, loading) => {
  if (!loading && arr) {
    const filteredJobs = {
      topJobs: [],
      recentJobs: [],
      moreJobs: []
    }

    // eslint-disable-next-line
    arr.map((job, i) => {
      const threeDaysAgo = dayjs().subtract(3, 'day')
      if (job.jobCandidates.length > 0 && job.jobCandidates[0].matchScore > 80) filteredJobs.topJobs = [...filteredJobs.topJobs, job]
      else if (job.jobCandidates.length > 0 && job.jobCandidates[0].matchScore < 80 && dayjs(job.createdAt).isAfter(threeDaysAgo)) filteredJobs.recentJobs = [...filteredJobs.recentJobs, job]
      else filteredJobs.moreJobs = [...filteredJobs.moreJobs, job]
    })
    return filteredJobs
  }
}

export const profileProgress = (obj, loading) => {
  if (!loading) {
    // DELETE KEYS THAT AREN'T USER EDITABLE
    delete obj.uid
    delete obj.authUid
    delete obj.addressDescription
    delete obj.biography
    delete obj.createdAt
    delete obj.updatedAt
    delete obj.deletedAt
    delete obj.jobPotentials
    delete obj.jobMatched
    delete obj.jobDigged
    delete obj.jobShortlisted
    delete obj.isVerified

    const totalKeys = Object.keys(obj).length
    let incompleteKeys = 0
    for (const key of Object.keys(obj)) {
      if (obj[key] === null || obj[key] === undefined) {
        incompleteKeys++
      }
    }

    return {
      progress: Math.floor(((totalKeys - incompleteKeys) / totalKeys) * 100),
      total: totalKeys,
      completed: totalKeys - incompleteKeys
    }
  }
}
