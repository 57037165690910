import React, { useEffect, useState } from 'react'
import { UID } from '../../hooks/useApi'
import db from '../../firebase'
import { Switch } from 'react-router'
import { PrivateRoute } from '../../components/helpers/PrivateRoute'
import { ChatOpen } from './ChatOpen'
import { Section } from '../../components/layout/Section'
import { Channel } from '../../components/blocks/Channel'

export const Chat = (props) => {
  // STATE
  const [channels, setChannels] = useState([])
  const [uid, setUid] = useState(() => window.localStorage.getItem('uid') || null)

  useEffect(() => {
    async function awaitUid () {
      if (uid === null || uid === undefined || uid === '') setUid(await UID())
      const unsubscribe = db
        .collection('channels')
        .where('candidate', '==', uid)
        .onSnapshot(snap => {
          setChannels(snap.docs.map(doc => ({
            id: doc.id,
            data: doc.data()
          })))
        })

      return () => unsubscribe()
    }

    awaitUid()
  }, [uid])

  return (
    <>
      <div className='col-xs-3 chat__list'>
        <Section style={{ marginBottom: '1rem' }}>
          <Section.Title>Chat</Section.Title>
          <Section.Footer>Your future potential is your hands</Section.Footer>
        </Section>
        {channels.length > 0 && channels.map(channel => (
          <Channel key={channel.id} channel={channel} />
        ))}
      </div>
      <Switch>
        <PrivateRoute path='/chat/:chatUid' component={ChatOpen} />
      </Switch>
    </>
  )
}
