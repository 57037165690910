import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { DiggerCard } from '../../components/blocks/DiggerCard'
import { Grid } from '../../components/blocks/Grid'
import { List } from '../../components/blocks/List'
import { LayoutButton } from '../../components/layout/LayoutButton'
import { Modal, modalReducer } from '../../components/layout/Modal'
import { Section } from '../../components/layout/Section'
import { Tabs } from '../../components/layout/Tabs'
import { useUser } from '../../hooks/useUser'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { Loader } from '../../components/blocks/Loader'
import { createMedia, createSkill, runMatches, searchSkills } from '../../hooks/useApi'
import { useDebounce } from '../../hooks/utils/useDebounce'
import { useMutation, useQueryClient } from 'react-query'
import { Alert } from '../../components/blocks/Alert'
import { useAlert } from '../../hooks/useAlert'
import { setEdjs } from '../../hooks/utils/useEdjs'
import { Field, Form, Formik } from 'formik'
import { useUploader } from '../../hooks/utils/useUploader'

export const Profile = () => {
  function useQuery () {
    return new URLSearchParams(useLocation().search)
  }

  // CONSTANTS
  const query = useQuery()
  const { isActive, message, openAlert } = useAlert()
  const noSkillMsg = 'Tap here so we can dig a little deeper for your skill'
  // eslint-disable-next-line
  const marks = {
    20: '20+'
  }
  const queryClient = useQueryClient()

  // STATE
  const [active, setActive] = useState(() => query.get('profile'))
  const [expModal, expModalDispatch] = useReducer(modalReducer, { open: false })
  const [years, setYears] = useState(0)
  const [loading, setLoading] = useState(false)
  const [input, setInput] = useState(null)
  const [selectedSkill, setSelectedSkill] = useState(null)
  const [matchUser, setMatchUser] = useState(false)
  const [file, setFile] = useState(null)
  const [dlUrl, setDlUrl] = useState(null)
  const debouncedInput = useDebounce(input, 500)
  // eslint-disable-next-line
  const [docModal, docModalDispatch] = useReducer(modalReducer, { open: false })

  const { progress, url } = useUploader(file)

  // API
  const userQuery = useUser()
  const { mutateAsync, isLoading } = useMutation(createSkill, {
    onSuccess: () => {
      queryClient.invalidateQueries('user')
      queryClient.invalidateQueries('jobs')
      openAlert('Successfully created skill')
      setMatchUser(true)
      setYears(0)
    },
    onError: (error) => {
      openAlert(error.response.data.message)
    },
    onMutate: () => queryClient.invalidateQueries('user'),
    onSettled: () => expModalDispatch({ type: 'close' })
  })
  const { mutateAsync: mutateCreateMedia, isLoading: createMediaLoading } = useMutation(createMedia, {
    onSuccess: () => {
      queryClient.invalidateQueries('user')
      queryClient.invalidateQueries('jobs')
      openAlert('Successfully created skill')
      docModalDispatch({ type: 'close' })
    },
    onError: (error) => {
      openAlert(error.response.data.message)
    },
    onMutate: () => queryClient.invalidateQueries('user'),
    onSettled: () => docModalDispatch({ type: 'close' })
  })

  useQuery('runMatches', runMatches, { enabled: matchUser })

  // METHODS
  useMemo(async (input) => {
    if (debouncedInput) {
      setLoading(true)
      document.getElementById('skills').innerHTML = ''
      const data = await searchSkills(debouncedInput)
      const newOptions = []
      if (data && data.length > 0) {
        const formattedSkills = data.map(skill => ({
          label: skill.name,
          key: skill.uid,
          skillUid: skill.uid
        }))

        formattedSkills.forEach(skill => {
          newOptions.push(`<option data-skill-uid="${skill.skillUid}">${skill.label}</option>`)
        })
      } else {
        newOptions.push(`<option value="Tap here so we can dig a little deeper for your skill">${debouncedInput}</option>`)
      }
      document.getElementById('skills').innerHTML = newOptions.join('')
      setLoading(false)
    }
  }, [debouncedInput])

  const onSkillChange = async e => {
    const input = e.target
    const list = input.getAttribute('list')
    const options = document.querySelectorAll(`#${list} option`)

    if (input.value === noSkillMsg) {
      const savedInput = input
      input.value = 'Loading...'
      setLoading(true)
      const newOptions = []
      const data = await searchSkills(savedInput, true)
      input.value = savedInput

      if (data && data.length > 0) {
        const formattedSkills = data.map(skill => ({
          label: skill.name,
          key: skill.uid,
          skillUid: skill.uid
        }))

        formattedSkills.forEach(skill => {
          newOptions.push(`<option data-skill-uid="${skill.skillUid}">${skill.label}</option>`)
        })
        setLoading(false)
      } else {
        setLoading(false)
        newOptions.push('<option>We couldn\'t find that skill, please try another</option>')
      }
      document.getElementById('skills').innerHTML = newOptions.join('')
    } else if (input.value.length > 2) {
      if (options.length === 0) {
        setInput(input.value)
      } else {
        if (input.value !== noSkillMsg) findValueInOptions(options, input.value)
      }
    }
  }

  const findValueInOptions = function (options, value) {
    let foundValue = false
    for (let index = 0; index < options.length; index++) {
      if (options[index].innerText === value) {
        foundValue = true
        setSelectedSkill({ skillUid: options[index].getAttribute('data-skill-uid'), name: options[index].innerText })
        break
      }
    }
    if (!foundValue) {
      setInput(input?.value)
      setSelectedSkill(null)
    }
  }

  useEffect(() => {
    if (url) {
      setDlUrl(url)
      setLoading(false)
      console.log('Download URL', url)
    }
  }, [url, progress])

  return (
    <div className='col-xs-12 col-sm-4 profile__panel'>
      <Alert isActive={isActive} message={message} />
      <Section style={{ marginBottom: '1rem' }}>
        <Section.Title>Profile</Section.Title>
      </Section>
      {!userQuery.isLoading && userQuery.data
        ? (
          <>
            <Modal
              open={expModal.open}
              onClose={() => expModalDispatch({ type: 'close' })}
              size='small'
              title='Add new experience'
            >
              <div className='skillInput'>
                <input placeholder='Search for your experience' onInput={onSkillChange} list='skills' name='skillInput' id='skillInput' style={{ marginBottom: '1rem' }} />
                {loading && (
                  <div className='skill__loader'>
                    <Loader />
                  </div>
                )}
              </div>
              <datalist id='skills' />
              <p style={{ fontWeight: 'bold' }} className='label'>
                Experience:
                {years === 0
                  ? ' Less than a year'
                  : years === 20
                    ? ' 20+ Years'
                    : ` ${years} Years`}
              </p>
              <Slider
                min={0}
                max={20}
                marks={marks}
                onChange={e => setYears(e)}
                className='skill__slider'
              />
              <button onClick={async () => await mutateAsync({ ...selectedSkill, experience: years })} disabled={!selectedSkill} style={{ marginTop: '1.75rem' }} className='button button__primary button--full'>
                {!selectedSkill
                  ? 'Select your experience'
                  : isLoading
                    ? <Loader />
                    : 'Submit'}
              </button>
            </Modal>
            <Modal
              open={docModal.open}
              onClose={() => docModalDispatch({ type: 'close' })}
              size='small'
              title='Add new document'
              caption='These document will be used to apply for a job'
            >
              <Formik
                initialValues={{
                  title: ''
                }}
                onSubmit={async (data) => {
                  let type
                  if (file.type.includes('image')) type = 'image'
                  else if (file.type.includes('application')) type = 'document'
                  else if (file.type.includes('video')) type = 'video'
                  else type = 'video'

                  if (dlUrl && file) {
                    await mutateCreateMedia({
                      name: data.title,
                      url: dlUrl,
                      type
                    })
                  } else {
                    openAlert('Please fill in all the fields')
                  }
                }}
              >
                {({ values, dirty, isValid }) => (
                  <Form autoComplete='off' className='onboarding__form'>
                    <Field placeholder='Document title' name='title' />

                    <label className='upload__doc'>
                      <Section size='small'>
                        <Section.Title>Click here</Section.Title>
                        <Section.Footer>
                          {loading
                            ? `Uploading: ${progress}%`
                            : dlUrl
                              ? 'Change Image'
                              : 'to upload a new document'}
                        </Section.Footer>
                      </Section>
                      <div className={!loading ? 'button button__primary' : 'button button__primary button--disabled'}>
                        {loading ? <Loader /> : <ion-icon name='add-outline' />}
                      </div>

                      <input
                        onChange={e => {
                          setLoading(true)
                          setFile(e.currentTarget.files[0])
                        }}
                        name='file'
                        type='file'
                        style={{ display: 'none' }}
                      />
                    </label>
                    <button disabled={!dlUrl} style={{ margin: '2rem 0' }} type='submit' className='button button__primary button--full'>{!dlUrl || dirty ? 'Fill in all fields' : createMediaLoading ? <Loader /> : 'Submit'}</button>
                  </Form>
                )}
              </Formik>
            </Modal>
            <DiggerCard user={userQuery.data} />
            <Tabs
              style={{ margin: '1rem 0 0.475rem 0' }}
              name='profile'
              onTabChange={setActive}
              active={active}
            >
              <Tabs.Tab value='overview'>
                <Grid gap={1}>
                  <LayoutButton>
                    <LayoutButton.Icon><ion-icon name='heart-outline' /></LayoutButton.Icon>
                    <LayoutButton.Content>
                      <LayoutButton.Caption>{userQuery.data?.jobDigged?.length + userQuery.data?.jobShortlisted?.length}</LayoutButton.Caption>
                      <LayoutButton.Title>Digged Jobs</LayoutButton.Title>
                    </LayoutButton.Content>
                  </LayoutButton>
                  <LayoutButton>
                    <LayoutButton.Icon><ion-icon name='link-outline' /></LayoutButton.Icon>
                    <LayoutButton.Content>
                      <LayoutButton.Caption>{userQuery.data?.jobMatched?.length + userQuery.data?.jobPotentials?.length}</LayoutButton.Caption>
                      <LayoutButton.Title>Matched Jobs</LayoutButton.Title>
                    </LayoutButton.Content>
                  </LayoutButton>
                </Grid>
                <Grid style={{ alignItems: 'center' }}>
                  <Section style={{ margin: '0' }} size='small'>
                    <Section.Title>Profile Details</Section.Title>
                  </Section>
                  <Link to='/app/profile/edit' className='button button__primary'>Edit</Link>
                </Grid>
                <div className='divider' />
                <Section>
                  <Section.Footer>Details</Section.Footer>
                </Section>
                <List>
                  <List.Item variant='list__item--white' icon={<ion-icon name='person-outline' />}>
                    <List.Caption>Name:</List.Caption>
                    {userQuery.data?.fullName}
                  </List.Item>
                  <List.Item icon={<ion-icon name='mail-outline' />}>
                    <List.Caption>Email:</List.Caption>
                    {userQuery.data.email}
                  </List.Item>
                  {userQuery.data.mobile && (
                    <List.Item icon={<ion-icon name='call-outline' />}>
                      <List.Caption>Mobile:</List.Caption>
                      {userQuery.data.mobile}
                    </List.Item>
                  )}
                </List>
                {userQuery.data.biographyAsDante !== null && (
                  <>
                    <Section>
                      <Section.Footer>Biography</Section.Footer>
                    </Section>
                    <div className='editor' dangerouslySetInnerHTML={userQuery.data.biographyAsDante !== null ? { __html: setEdjs(userQuery.data?.biographyAsDante?.blocks) } : null} />
                  </>
                )}
                <Section>
                  <Section.Footer>Work Preferences</Section.Footer>
                </Section>
                <List>
                  <Grid>
                    <List.Item>
                      <List.Caption>Work Status:</List.Caption>
                      {userQuery.data.isJobSeeking ? 'Looking' : 'Not Looking'}
                    </List.Item>
                    <List.Item>
                      <List.Caption>Can Relocate:</List.Caption>
                      {userQuery.data.canRelocate ? 'Yes' : 'No'}
                    </List.Item>
                  </Grid>
                </List>
              </Tabs.Tab>
              <Tabs.Tab value='skills'>
                <List>
                  {userQuery.data.candidateSkills.map(skill => (
                    <List.Item
                      key={skill.uid}
                      uid={skill.skill.uid}
                      drag
                      variant='border'
                      color={
                      skill.experience > 9
                        ? 'green'
                        : skill.experience > 4
                          ? 'blue'
                          : skill.experience > 1
                            ? 'darkblue'
                            : 'gray'
                    }
                    >
                      <List.Caption>{skill.experience} Years Experience</List.Caption>
                      {skill.skill.name}
                    </List.Item>
                  ))}
                </List>
                <button type='button' onClick={() => expModalDispatch({ type: 'open' })} style={{ marginTop: '1rem' }} className='button button__primary button--full'>Add new skill</button>
              </Tabs.Tab>
              <Tabs.Tab value='documents'>
                <List>
                  {userQuery.data.candidateMedia.map(doc => (
                    <List.Item key={doc.uid} uid={doc.uid} drag variant='border'>
                      <List.Caption>{doc.type}</List.Caption>
                      {doc.name}
                    </List.Item>
                  ))}
                </List>
                <button onClick={() => docModalDispatch({ type: 'open' })} style={{ marginTop: '1rem' }} className='button button__primary button--full'>Add new document</button>
              </Tabs.Tab>
            </Tabs>
          </>
          )
        : !userQuery.data
            ? <div>Unable to fetch profile</div>
            : (
              <div>Loading...</div>
              )}
    </div>
  )
}
