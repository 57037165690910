import { motion } from 'framer-motion'
import React, { useEffect, useReducer } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Alert } from '../../components/blocks/Alert'
import { List } from '../../components/blocks/List'
import { Loader } from '../../components/blocks/Loader'
import { Row } from '../../components/layout/Row'
import { Section } from '../../components/layout/Section'
import { useAlert } from '../../hooks/useAlert'
import { applyForJob, forceApplyForJob } from '../../hooks/useApi'
import { useJob } from '../../hooks/useJobs'
import { useUser } from '../../hooks/useUser'

const proofReducer = (state, action) => {
  const { type, payload, index, force } = action
  switch (type) {
    case 'initial':
      return {
        ...state,
        currStep: 0,
        length: payload.length,
        steps: payload.map((requirement, i) => {
          return {
            step: i,
            jobRequirementUid: requirement.uid,
            title: `Step ${i + 1}: ${requirement.requirement}`,
            caption: 'Choose from your documents or upload a new one',
            name: requirement.requirement,
            isFirst: i === 0,
            isLast: i === payload.length - 1
          }
        }),
        selectedDocs: Array(payload.length).fill(null),
        isForced: !(force.length > 0)
      }
    case 'select-doc':
      state.selectedDocs[index] = payload
      return {
        ...state
      }
    case 'next':
      return {
        ...state,
        currStep: state.currStep++
      }
    case 'back':
      return {
        ...state,
        currStep: state.currStep--
      }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const Proof = () => {
  // STATE
  const [state, dispatch] = useReducer(proofReducer, {
    currStep: 0,
    length: 0,
    steps: [],
    selectedDocs: [],
    isForced: false
  })

  // CONSTATNTS
  const { jobUid } = useParams()
  const { isActive, message, openAlert } = useAlert()
  const queryClient = useQueryClient()
  const { push } = useHistory()

  console.log('THIS IS THE JOB UID', state)

  // API
  const userQuery = useUser()
  const jobQuery = useJob(jobUid)
  const { mutateAsync: forceApply, isLoading: forceLoading } = useMutation(forceApplyForJob, {
    onSuccess: async () => {
      queryClient.invalidateQueries('jobs')
      queryClient.invalidateQueries('job')
      push({
        pathname: `/app/job/${jobUid}`
      })
    },
    onError: (error) => {
      openAlert(error.response.data.message)
      setTimeout(() => {
        push({
          pathname: `/app/job/${jobUid}`,
          state: {
            message: error.response.data.message
          }
        })
      }, 1500)
    }
  })
  const { mutateAsync: apply, isLoading: applyLoading } = useMutation(applyForJob, {
    onSuccess: () => {
      queryClient.invalidateQueries('jobs')
      queryClient.invalidateQueries('job')
      push({
        pathname: `/app/job/${jobUid}`
      })
    },
    onError: (error) => {
      openAlert(error.response.data.message)
      setTimeout(() => {
        push({
          pathname: `/app/job/${jobUid}`,
          state: {
            message: error.response.data.message
          }
        })
      }, 1500)
    }
  })

  // EFFECTS
  useEffect(() => {
    if (!jobQuery.isLoading) {
      dispatch({ type: 'initial', payload: jobQuery.data.jobRequirements, force: jobQuery.data.jobCandidates })
    }
    // eslint-disable-next-line
  }, [jobQuery.isLoading])

  const submitApplication = async () => {
    if (state.isForced) {
      console.log('WITHOUT QUERY', jobUid.replace('?forced=true', ''))
      await forceApply({
        jobUid: jobUid.replace('?forced=true', ''),
        jobRequirementCandidateProof: state.selectedDocs
      })
    } else {
      await apply({
        jobUid: jobUid.replace('?forced=true', ''),
        jobRequirementCandidateProof: state.selectedDocs
      })
    }
  }

  return (
    <Row className='job__proof'>
      <Alert isActive={isActive} message={message} />
      <div className='col-xs-12 col-md-5 document__list'>
        <Section>
          <Section.Title>Your Documents</Section.Title>
          <Section.Footer>Choose from your documents or upload a new one</Section.Footer>
        </Section>
        <div className='divider' />
        {!userQuery.isLoading && !jobQuery.isLoading && jobQuery.data
          ? (
            <List>
              {userQuery.data && userQuery.data.candidateMedia.map(doc => {
                const { uid } = doc.uid
                const indexOfDoc = state.selectedDocs.findIndex(document => {
                  if (document) {
                    return doc.candidateMediaUid === uid
                  } else {
                    return false
                  }
                })
                const docFromIndex = state.selectedDocs[indexOfDoc]
                if (docFromIndex === undefined) {
                  return (
                    <List.Item
                      key={doc.uid}
                      onClick={() => {
                        dispatch({
                          type: 'select-doc',
                          index: state.currStep,
                          payload: {
                            jobRequirementName: state.steps[state.currStep].name,
                            candidateMediaName: doc.name,
                            candidateMediaUid: doc.uid,
                            jobRequirementUid: state.steps[state.currStep].jobRequirementUid,
                            jobCandidateUid: jobQuery.data.jobCandidates[0]?.uid
                          }
                        })
                      }}
                    >
                      <List.Caption>Format: {doc.type}</List.Caption>
                      {doc.name}
                    </List.Item>
                  )
                } else if (docFromIndex && docFromIndex.candidateMediaUid !== doc.uid) {
                  return (
                    <List.Item
                      key={doc.uid}
                      onClick={() => {
                        dispatch({
                          type: 'select-doc',
                          index: state.currStep,
                          payload: {
                            jobRequirementName: state.steps[state.currStep].name,
                            candidateMediaName: doc.name,
                            candidateMediaUid: doc.uid,
                            jobRequirementUid: state.steps[state.currStep].jobRequirementUid,
                            jobCandidateUid: jobQuery.data.jobCandidates[0]?.uid
                          }
                        })
                      }}
                    >
                      <List.Caption>Format: {doc.type}</List.Caption>
                      {doc.name}
                    </List.Item>
                  )
                } else return null
              })}
            </List>
            )
          : (<div>Loading...</div>)}
      </div>
      <div className='col-xs-12 col-md-7 required__docs'>
        {state.steps.length > 0
          ? (
            <>
              <Section>
                <Section.Title>{state.steps[state.currStep].title}</Section.Title>
                <Section.Footer>Haven't uploaded this yet? Click the + to upload a new document</Section.Footer>
              </Section>
              <div className='progress'>
                <motion.div
                  className='progress__bar'
                  animate={{
                    width: `${(state.currStep + 1 / state.steps.length) * 100}%`
                  }}
                  transition={{ duration: 0.3, ease: [0.6, 0.05, -0.01, 0.9] }}
                />
              </div>

              <Section style={{ marginTop: '1rem' }} size='small'>
                <Section.Title>Selected Documents</Section.Title>
              </Section>
              <List>
                {state.selectedDocs.map(doc => {
                  if (doc) {
                    return (
                      <List.Item key={doc.candidateMediaUid}>
                        <List.Caption>{doc.jobRequirementName}</List.Caption>
                        {doc.candidateMediaName}
                      </List.Item>
                    )
                  } else return null
                })}
              </List>

              <div className='upload__doc'>
                <Section size='small'>
                  <Section.Title>Got more documents?</Section.Title>
                  <Section.Footer>tGo to your profile to upload more</Section.Footer>
                </Section>
                <Link className='button button__primary button--above'>Go to profile</Link>
              </div>
              <div className='docs__actions'>
                {state.currStep > 0 && (
                  <button
                    className='button button__secondary'
                    onClick={() => dispatch({ type: 'back' })}
                  >
                    Back
                  </button>
                )}
                <button
                  disabled={!state.selectedDocs[state.currStep]}
                  className={`button ${state.selectedDocs[state.currStep] ? 'button__primary' : 'button__secondary'}`}
                  onClick={
                    () => {
                      if (state.selectedDocs[state.currStep] === null) {
                        openAlert('Select a document')
                      } else if (state.currStep < state.length - 1) {
                        dispatch({ type: 'next' })
                      } else {
                        submitApplication()
                      }
                    }
                  }
                >
                  {state.currStep < state.length - 1 && state.selectedDocs[state.currStep]
                    ? 'Next'
                    : state.currStep < state.length - 1 && !state.selectedDocs[state.currStep]
                      ? 'Select a document'
                      : forceLoading || applyLoading
                        ? <Loader />
                        : 'Submit'}
                </button>
              </div>
            </>
            )
          : <div>x</div>}
      </div>

    </Row>
  )
}
