import React, { createContext, useContext, useReducer } from 'react'

// DEFINE CONTEXT
const GlobalStateContext = createContext()
const GlobalDispatchContext = createContext()

// REDUCER
const globalReducer = (state, action) => {
  const app = document.getElementById('app')
  const { type } = action
  switch (type) {
    case 'RESET':
      if (state.sidebar === 'hidden') {
        app.classList.remove('app--full')
        window.localStorage.setItem('sidebar', false)
        return {
          ...state,
          sidebar: false
        }
      } else {
        return {
          ...state
        }
      }

    case 'TOGGLE_MENU': {
      // const sidebarHistory = window.localStorage.getItem('sidebar')
      if (state.sidebar) app.classList.remove('app--open')
      else app.classList.add('app--open')

      window.localStorage.setItem('sidebar', !state.sidebar)

      return {
        ...state,
        sidebar: !state.sidebar
      }
    }
    case 'HIDE_MENU': {
      app.classList.remove('app--open')
      app.classList.add('app--full')
      window.localStorage.setItem('sidebar', 'hidden')
      return {
        ...state,
        sidebar: 'hidden'
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const GlobalProvider = ({ children }) => {
  const sidebar = window.localStorage.getItem('sidebar')
  const [state, dispatch] = useReducer(globalReducer, { sidebar })

  return (
    <GlobalDispatchContext.Provider value={dispatch}>
      <GlobalStateContext.Provider value={state}>
        {children}
      </GlobalStateContext.Provider>
    </GlobalDispatchContext.Provider>
  )
}

// custom hooks for when we want to use our global state
export const useGlobalStateContext = () => useContext(GlobalStateContext)

export const useGlobalDispatchContext = () => useContext(GlobalDispatchContext)
