import { motion } from 'framer-motion'
import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { Loader } from '../../components/blocks/Loader'
import { Container } from '../../components/layout/Container'
import { useUser } from '../../hooks/useUser'
import { ReactComponent as Illustration } from '../../components/onboarding.svg'
import { useHistory, useLocation } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { Grid } from '../../components/blocks/Grid'
import { List } from '../../components/blocks/List'
import { useDebounce } from '../../hooks/utils/useDebounce'
import { onboardCandidate, runMatches, searchSkills } from '../../hooks/useApi'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { Editor } from '../../components/layout/Editor'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { Section } from '../../components/layout/Section'
import { useUploader } from '../../hooks/utils/useUploader'
import { Alert } from '../../components/blocks/Alert'
import { useAlert } from '../../hooks/useAlert'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { useGlobalDispatchContext } from '../../hooks/useCtx'

const steps = [{ index: 0, first: true, title: 'Lets start' }, { step: 1, title: 'Are you looking for a new Job?', caption: "Letting people know you're looking might increase your chances of being hired" }, { step: 2, title: 'What experience do you have?', caption: 'You need at least one. We use these to match you to your future jobs' }, { step: 3, title: 'Tell us about yourself...', caption: 'Make it look pretty because people will see it' }, { step: 4, title: 'Location, Location, Location...', caption: 'Where do you currently live?' }, { step: 5, title: 'Almost done!', caption: 'Upload a picture of yourself, it can only help' }, { step: 6, title: 'Last step...', caption: 'Let us use some of your details to enhance your experience', last: true }]
const types = ['image/png', 'image/jpeg', 'image/jpg']

const onboardingReducer = (state, action) => {
  const { type, payload } = action
  const searchParam = new URLSearchParams(window.location.search)
  switch (type) {
    case 'initial':
      console.log(payload)
      if (payload <= steps.length) {
        // && state.skills.length > 0
        if (payload > 3 && state.skills.length < 1) {
          searchParam.set('step', 1)
          const newRelativePathQuery = window.location.pathname + '?' + searchParam.toString()
          window.history.pushState(null, '', newRelativePathQuery)
          return {
            ...state,
            currentStep: 0,
            step: steps[0]
          }
        } else {
          return {
            ...state,
            currentStep: payload - 1,
            step: steps[payload - 1],
            hasNext: payload - 1 < steps.length - 1,
            hasPrev: payload - 1 > 0,
            status: payload - 1 === steps.length - 1 ? 'submit' : 'initial'
          }
        }
      } else {
        searchParam.set('step', 1)
        const newRelativePathQuery = window.location.pathname + '?' + searchParam.toString()
        window.history.pushState(null, '', newRelativePathQuery)
        return {
          ...state,
          currentStep: 0,
          step: steps[0]
        }
      }
    case 'next':
      if (state.currentStep < steps.length - 1) {
        const searchParam = new URLSearchParams(window.location.search)
        searchParam.set('step', state.currentStep + 2)
        const newRelativePathQuery = window.location.pathname + '?' + searchParam.toString()
        window.history.pushState(null, '', newRelativePathQuery)
      }

      return {
        ...state,
        currentStep: state.currentStep + 1,
        step: steps[state.currentStep + 1],
        hasNext: state.currentStep + 1 < steps.length - 1,
        hasPrev: true,
        status: state.currentStep + 1 === steps.length - 1 ? 'submit' : 'initial'
      }
    case 'prev':
      if (state.currentStep > 0) {
        const searchParam = new URLSearchParams(window.location.search)
        searchParam.set('step', state.currentStep)
        const newRelativePathQuery = window.location.pathname + '?' + searchParam.toString()
        window.history.pushState(null, '', newRelativePathQuery)
      }
      return {
        ...state,
        currentStep: state.currentStep - 1,
        step: steps[state.currentStep - 1],
        hasNext: true,
        hasPrev: state.currentStep - 1 > 0,
        status: 'initial'
      }
    case 'push-skill':
      return {
        ...state,
        skills: [...state.skills, { ...state.selectedSkill, experience: state.experience }],
        input: null,
        selectedSkill: null,
        experience: 0
      }
    case 'select-skill':
      return {
        ...state,
        selectedSkill: payload
      }
    case 'input':
      return {
        ...state,
        input: payload
      }
    case 'toggle-loading':
      return {
        ...state,
        loading: payload
      }
    case 'years':
      return {
        ...state,
        experience: payload
      }
    case 'address-description':
      return {
        ...state,
        addressDescription: payload
      }
    case 'select-location':
      return {
        ...state,
        address: payload.point,
        addressDescription: payload.addressDescription
      }
    case 'upload-file':
      if (payload) {
        if (types.includes(payload.type)) {
          return {
            ...state,
            file: payload,
            loading: true
          }
        } else {
          return {
            ...state,
            error: 'Please select an image file (png or jpg)'
          }
        }
      } else return { ...state }
    case 'url':
      return {
        ...state,
        photoUrl: payload,
        loading: false
      }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const Onboarding = () => {
  const [editor, setEditor] = useState(null)
  const [matchUser, setMatchUser] = useState(false)
  const [state, dispatch] = useReducer(
    onboardingReducer, {
      steps,
      step: null,
      currentStep: 0,
      hasNext: true,
      hasPrev: false,
      status: 'initial',
      skills: [],
      input: null,
      loading: false,
      experience: 0,
      selectedSkill: null,
      addressDescription: '',
      address: null,
      file: null,
      photoUrl: null
    }
  )

  // CONSTANTS
  const globalDispatch = useGlobalDispatchContext()
  const queryClient = useQueryClient()
  const { isActive, message, openAlert } = useAlert()
  const { progress, url } = useUploader(state.file)
  const { push } = useHistory()
  const debouncedInput = useDebounce(state.input, 500)
  const noSkillMsg = 'Tap here so we can dig a little deeper for your skill'
  function useQueryParam () {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQueryParam()
  const marks = {
    20: '20+'
  }

  // API
  const userQuery = useUser()
  useQuery('matches', runMatches, {
    enabled: matchUser,
    onSettled: () => {
      dispatch({ type: 'toggle-loading', payload: false })
      push('/app')
    }
  })
  const { mutateAsync } = useMutation(onboardCandidate, {
    onSettled: () => {
      setMatchUser(true)
      queryClient.invalidateQueries('user')
    }
  })

  // EFFECTS
  useEffect(() => {
    // if (open && open !== 'hidden') setOpen('hidden')
    globalDispatch({ type: 'HIDE_MENU' })
    if (Number.isInteger(parseInt(query.get('step')))) dispatch({ type: 'initial', payload: parseInt(query.get('step')) })
    else dispatch({ type: 'initial' })
    // eslint-disable-next-line
  }, [])
  useMemo(async () => {
    if (debouncedInput) {
      // setLoading(true)
      dispatch({ type: 'toggle-loading', payload: true })
      document.getElementById('skills').innerHTML = ''
      const data = await searchSkills(debouncedInput)
      const newOptions = []
      if (data && data.length > 0) {
        const formattedSkills = data.map(skill => ({
          label: skill.name,
          key: skill.uid,
          skillUid: skill.uid
        }))

        formattedSkills.forEach(skill => {
          newOptions.push(`<option data-skill-uid="${skill.skillUid}">${skill.label}</option>`)
        })
      } else {
        newOptions.push(`<option value="${noSkillMsg}">${debouncedInput}</option>`)
      }

      document.getElementById('skills').innerHTML = newOptions.join('')
      if (data && data.length > 0) {
        findValueInOptions(document.querySelectorAll('#skills option'), debouncedInput)
      }

      dispatch({ type: 'toggle-loading', payload: false })
    }
  }, [debouncedInput])
  useEffect(() => {
    if (url) dispatch({ type: 'url', payload: url })
  }, [url])
  useEffect(() => {
    if (state.error) openAlert(state.error)
    // eslint-disable-next-line
  }, [state.error])

  // METHODS
  const onSkillChange = async e => {
    const input = e.target
    const list = input.getAttribute('list')
    const options = document.querySelectorAll(`#${list} option`)

    if (input.value === noSkillMsg) {
      const savedInput = state.input
      input.value = 'Loading...'
      dispatch({ type: 'toggle-loading', payload: true })
      const newOptions = []
      const data = await searchSkills(savedInput, true)
      input.value = savedInput

      if (data && data.length > 0) {
        const formattedSkills = data.map(skill => ({
          label: skill.name,
          key: skill.uid,
          skillUid: skill.uid
        }))

        formattedSkills.forEach(skill => {
          newOptions.push(`<option data-skill-uid="${skill.skillUid}">${skill.label}</option>`)
        })
        dispatch({ type: 'toggle-loading', payload: false })
      } else {
        dispatch({ type: 'toggle-loading', payload: false })
        newOptions.push(`<option>We couldn't find ${savedInput}, please try another</option>`)
      }
      document.getElementById('skills').innerHTML = newOptions.join('')
    } else if (input.value.length > 2) {
      if (options.length === 0) {
        dispatch({ type: 'input', payload: input.value })
        // setSkillInput(input.value)
      } else {
        if (input.value !== noSkillMsg) findValueInOptions(options, input.value)
      }
    }
  }

  const findValueInOptions = function (options, value) {
    let foundValue = false
    for (let index = 0; index < options.length; index++) {
      if (options[index].innerText === value) {
        foundValue = true
        dispatch({ type: 'select-skill', payload: { skillUid: options[index].getAttribute('data-skill-uid'), name: options[index].innerText } })
        break
      }
    }
    if (!foundValue) {
      dispatch({ type: 'input', payload: value })
      dispatch({ type: 'select-skill', payload: null })
    }
  }

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value)
    const latLng = await getLatLng(results[0])
    dispatch({ type: 'address-description', payload: value })
    dispatch({
      type: 'select-location',
      payload: {
        addressDescription: value,
        point: {
          type: 'Point',
          coordinates: [latLng.lat, latLng.lng]
        }
      }
    })
  }

  return (
    <Container addedClass='onboarding' centered>
      <Alert isActive={isActive} message={message} />
      <h1>{state.step?.title}</h1>
      <p className='caption'>{state.step?.caption}</p>
      <div className='progressBar'>
        <motion.div
          className='progressBar__inner'
          initial={{
            width: `${(state.currentStep / state.steps.length) * 100}%`
          }}
          animate={{
            width: `${(state.currentStep / state.steps.length) * 100}%`
          }}
        />
      </div>
      {!userQuery.isLoading
        ? (
          <Formik
            initialValues={{
              // seeking: 'no',
              // canRelocate: 'yes'
            }}
            validateOnChange
            onSubmit={async (data) => {
              dispatch({ type: 'toggle-loading', payload: true })
              const personToOnboard = {
                dateOfBirth: data.dateOfBirth,
                educationLevel: data.educationLevel,
                gender: data.gender,
                isJobSeeking: data.seeking === 'yes',
                canRelocate: data.canRelocate === 'yes',
                race: data.race,
                photoUrl: state.photoUrl,
                candidateSkills: state.skills,
                addressDescription: state.addressDescription,
                address: state.address,
                remoteWork: data.remote,
                biographyAsDante: await editor.save().then(data => data)
              }
              mutateAsync(personToOnboard)
            }}
          >
            {({ values, dirty, isValid }) => (
              <Form autoComplete='off' className='onboarding__form'>
                {(() => {
                  switch (state.currentStep) {
                    case 0:
                      return (
                        <div className='greeting'>
                          <h3>Hey {userQuery.data.fullName.split(' ')[0]}, glad your joining us</h3>
                          <p>To get you started we just need to ask you a few questions</p>
                          <div className='illustration'>
                            <Illustration />
                          </div>
                        </div>
                      )
                    case 1:
                      return (
                        <div className='seeking'>
                          <Grid gap={1}>
                            <label className='layout__button'>
                              <Field name='seeking' value='yes' className='radio' type='radio' />
                              <div className='layoutButton__icon'><ion-icon name='search-outline' /></div>
                              <div className='layoutButton__content'>
                                <h4 className='layoutButton__title'>Looking</h4>
                                <p className='layoutButton__caption smaller'>Let them know you mean business</p>
                              </div>
                            </label>
                            <label className='layout__button'>
                              <Field name='seeking' value='no' className='radio' type='radio' />
                              <div className='layoutButton__icon'><ion-icon name='hourglass-outline' /></div>
                              <div className='layoutButton__content'>
                                <h4 className='layoutButton__title'>Not Looking</h4>
                                <p className='layoutButton__caption smaller'>Play the field a little bit. No pressure</p>
                              </div>
                            </label>
                          </Grid>
                        </div>
                      )
                    case 2:
                      return (
                        <div className='experience'>
                          <div className='skillInput'>
                            <input onInput={onSkillChange} placeholder='Search for your experience' list='skills' name='skillInput' id='skillInput' style={{ marginBottom: '1rem' }} />
                            {state.loading && (
                              <div className='skill__loader'>
                                <Loader />
                              </div>
                            )}
                          </div>
                          <datalist id='skills' />
                          <p style={{ fontWeight: 'bold' }} className='label'>
                            Experience:
                            {state.experience === 0
                              ? ' Less than a year'
                              : state.experience === 20
                                ? ' 20+ Years'
                                : ` ${state.experience} Years`}
                          </p>
                          <Slider
                            min={0}
                            max={20}
                            marks={marks}
                            className='skill__slider'
                            onChange={(e) => dispatch({ type: 'years', payload: e })}
                          />
                          {state.selectedSkill && <button type='button' onClick={() => dispatch({ type: 'push-skill' })} className='button button__primary add__skill'><ion-icon name='add-outline' /> Add Skill</button>}
                          <p className='section__title'>Your Skills:</p>
                          <List>
                            {state.skills.map(skill => (
                              <List.Item
                                key={skill.uid}
                                uid={skill.uid}
                                drag
                                variant='border'
                                color={
                                  skill.experience > 9
                                    ? 'green'
                                    : skill.experience > 4
                                      ? 'blue'
                                      : skill.experience > 1
                                        ? 'darkblue'
                                        : 'gray'
                                }
                              >
                                <List.Caption>{skill.experience} Years Experience</List.Caption>
                                {skill.name}
                              </List.Item>
                            ))}
                          </List>

                        </div>
                      )
                    case 3:
                      return (
                        <Editor instance={editor} setInstance={setEditor} />
                      )
                    case 4:
                      return (
                        <div className='location'>
                          <PlacesAutocomplete
                            value={state.addressDescription}
                            onSelect={handleSelect}
                            onChange={e => dispatch({ type: 'address-description', payload: e })}
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div style={{ position: 'relative' }}>
                                <input {...getInputProps({ placeholder: 'Type Address' })} />
                                {suggestions.length > 0 && (
                                  <div className='address-dropdown'>
                                    {loading ? <Loader /> : null}
                                    {suggestions.map((suggestion, i) => {
                                      const className = suggestion.active ? 'address-dropdown__item address-dropdown__item--active' : 'address-dropdown__item'
                                      return (
                                        <div
                                          key={i}
                                          {...getSuggestionItemProps(suggestion, {
                                            className
                                          })}
                                        >
                                          <p className='dropdown__icon'><ion-icon name='location-outline' /></p>
                                          <p className='address-dropdown__item__address'>{suggestion.formattedSuggestion.mainText} <span>{suggestion.formattedSuggestion.secondaryText}</span></p>
                                        </div>
                                      )
                                    })}
                                  </div>
                                )}
                              </div>
                            )}
                          </PlacesAutocomplete>
                          <Section style={{ margin: '1.75rem 0 1rem 0' }} size='small'>
                            <Section.Title style={{ marginBottom: '0.375rem' }}>Willing to move?</Section.Title>
                            <Section.Footer>Are you willing to relocate for a job</Section.Footer>
                          </Section>
                          <Grid gap={1}>
                            <label className='layout__button'>
                              <Field name='canRelocate' id='seeking_yes' value='no' className='radio' type='radio' />
                              <div className='layoutButton__icon'><ion-icon name='search-outline' /></div>
                              <div className='layoutButton__content'>
                                <h4 className='layoutButton__title'>No</h4>
                                <p className='layoutButton__caption smaller'>Show me jobs that are near me</p>
                              </div>
                            </label>
                            <label className='layout__button'>
                              <Field name='canRelocate' id='seeking_no' value='yes' className='radio' type='radio' />
                              <div className='layoutButton__icon'><ion-icon name='hourglass-outline' /></div>
                              <div className='layoutButton__content'>
                                <h4 className='layoutButton__title'>Yes</h4>
                                <p className='layoutButton__caption smaller'>Show me jobs regardless of location</p>
                              </div>
                            </label>
                          </Grid>
                          <Section style={{ margin: '1.75rem 0 1rem 0' }} size='small'>
                            <Section.Title style={{ marginBottom: '0.375rem' }}>Work Remotely</Section.Title>
                            <Section.Footer>Or just looking to get out the office</Section.Footer>
                          </Section>
                          <label className='layout__button'>
                            <Field name='remote' id='remote_yes' className='radio' type='checkbox' />
                            <div className='layoutButton__icon'><ion-icon name='cafe-outline' /></div>
                            <div className='layoutButton__content'>
                              <h4 className='layoutButton__title'>Remote</h4>
                              <p className='layoutButton__caption smaller'>I want to work from home (or a coffee shop)</p>
                            </div>
                          </label>
                        </div>
                      )
                    case 5:
                      return (
                        <div className='edit__avatar'>
                          <Section style={{ margin: '1.75rem 0 1rem 0' }} size='small'>
                            <Section.Title>Profile Picture</Section.Title>
                          </Section>
                          <div className='profile__avatar' style={{ backgroundImage: `url(${state.photoUrl})` }}>
                            {!state.photoUrl && <ion-icon name='camera-outline' />}
                          </div>
                          <label className='document__upload'>
                            <div className={!state.loading ? 'button button__primary' : 'button button__primary button--disabled'}>
                              {state.loading ? <Loader /> : <ion-icon name='image-outline' />}
                            </div>
                            {state.loading
                              ? <p>Uploading: {progress}%</p>
                              : state.photoUrl
                                ? <p>Change Image</p>
                                : <p>Click to upload</p>}
                            <input type='file' style={{ display: 'none' }} onChange={e => dispatch({ type: 'upload-file', payload: e.currentTarget.files[0] })} />
                          </label>
                        </div>
                      )
                    case 6:
                      return (
                        <div className='additional'>
                          <label className='label'>Mobile Number</label>
                          <Field className='above' name='mobile' type='tel' id='mobile' placeholder='Phone Number' />
                          <label className='label'>Gender</label>
                          <Field className='above' name='gender' id='gender' placeholder='Enter your gender' />
                          <label className='label'>Race</label>
                          <Field className='above' name='race' as='select' id='race'>
                            <option value={null}>Select a race...</option>
                            <option value='black'>Black / African</option>
                            <option value='coloured'>Coloured</option>
                            <option value='white'>White</option>
                            <option value='asian'>Asian</option>
                            <option value='indian'>Indian</option>
                            <option value='indian'>Other</option>
                          </Field>
                          <label className='label'>Education Level</label>
                          <Field className='above' name='educationLevel' as='select' id='educationLevel' placeholder='Education Level'>
                            <option value={null}>Select an education level</option>
                            <option value='certificate'>Certificate</option>
                            <option value='course'>Course</option>
                            <option value='diploma'>Diploma</option>
                            <option value='higher_certificate'>Higher Certificate</option>
                            <option value='degree'>Degree</option>
                            <option value='honourary_degree'>Honourary Degree</option>
                            <option value='honours'>Honours</option>
                            <option value='masters'>Masters</option>
                            <option value='doctorate'>Doctorate</option>
                          </Field>
                        </div>
                      )
                    default:
                      return null
                  }
                })()}
                <div className='onboarding__actions'>
                  {state.hasPrev && <button type='button' onClick={() => dispatch({ type: 'prev' })} className='button button__secondary'><ion-icon name='chevron-back-outline' /></button>}
                  {state.hasNext &&
                    <button
                      type='button'
                      onClick={
                        state.currentStep === 2 && state.skills.length < 1
                          ? () => openAlert('Please add an experience')
                          : () => dispatch({ type: 'next' })
                      }
                      className='button button__primary button--full'
                    >
                      {state.currentStep === 2 && state.skills.length < 1 ? 'Please add an experience' : 'Next'}
                    </button>}
                  {state.status === 'submit' && <button type='submit' className='button button__primary button--full'>Submit</button>}
                </div>
              </Form>
            )}
          </Formik>
          )
        : (
          <Loader />
          )}
    </Container>
  )
}
