import React, { useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Input } from '../../components/blocks/Input'
import { useQuery } from 'react-query'
import { getCurrentUser } from '../../hooks/useApi'
import { AuthContext } from '../../hooks/useAuth'
import { analytics, auth } from '../../firebase'
import { Loader } from '../../components/blocks/Loader'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { useGlobalDispatchContext } from '../../hooks/useCtx'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email').required('Email is required'),
  password: Yup.string().required('Password is required')
})

export const Login = () => {
  const [makeRequest, setMakeRequest] = useState(false)
  const [loading, setLoading] = useState(false)

  // CONSTANTS
  const dispatch = useGlobalDispatchContext()
  const { currentUser } = useContext(AuthContext)
  const history = useHistory()

  // API
  const { isLoading } = useQuery('user', getCurrentUser, {
    enabled: makeRequest && !!currentUser,
    onSuccess: () => {
      dispatch({ type: 'RESET' })
      history.push('/')
    }
  })

  useEffect(() => {
    dispatch({ type: 'HIDE_MENU' })
    // eslint-disable-next-line
  }, [])

  return (
    <div className='auth__page'>
      <div className='auth__content'>
        <h1 className='auth__title'>Sign In</h1>
        <p className='auth__message'>Welcome back to digger, where jobs find you</p>

        <Formik
          validateOnChange
          validationSchema={validationSchema}
          initialValues={{
            email: '',
            password: null,
            remember: false
          }}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true)
            auth
              .signInWithEmailAndPassword(values.email, values.password)
              .then(res => {
                if (process.env.NODE_ENV === 'production') {
                  const method = res?.credential?.signInMethod
                  analytics.logEvent('login', { method })
                }
                setLoading(false)
                setMakeRequest(x => !x)
              })
              .catch(error => {
                setLoading(false)
                alert(error.message)
              })
          }}
        >
          {({ values, errors, isSubmitting, dirty, isValid }) => (
            <Form className='auth__card'>
              <Input placeholder='Your Email' className='above' type='email' name='email' />
              <Input placeholder='Enter Password' className='above' type='password' name='password' />
              <div className='auth__extras'>
                <label className='checkbox__inline'><input type='checkbox' name='remember' />Remember Me</label>
                {/* <p className='forgot'>Forgot Password?</p> */}
                <p className='forgot'>Forgot Password?</p>
              </div>
              <button type='submit' className='button button__primary button--full'>
                {isLoading || loading ? <Loader /> : 'Sign In'}
              </button>
            </Form>
          )}
        </Formik>
        <Link to='/auth/register' className='auth__caption'>Don't have a digger account?</Link>
      </div>
    </div>
  )
}
