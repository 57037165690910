import React from 'react'

export const DiggerCard = ({ user }) => {
  function formatString (string) {
    const stringToMutate = string
    if (stringToMutate.includes('_')) {
      const withoutUnderscore = stringToMutate.replace('_', ' ')
      return withoutUnderscore
    } else {
      return stringToMutate
    }
  }
  return (
    <div className='diggerCard__wrapper'>
      <div className='diggerCard__details'>
        <img src={user.photoUrl} width='20' height='20' alt='Profile' />
        <h3>{user.fullName}</h3>
      </div>
      {user?.educationLevel && (
        <>
          {user?.educationLevel.includes('secondary_')
            ? <h3 className='diggerCard__title'><span>Education Level: </span>{user?.educationLevel.replace('secondary_', '')}th Grade</h3>
            : user?.educationLevel === 'certificate'
              ? <h3 className='diggerCard__title'><span>Education Level: </span>Grade 12 Certificate</h3>
              : user?.educationLevel
                ? <h3 className='diggerCard__title'><span>Education Level: </span>{formatString(user?.educationLevel)}</h3>
                : null}

        </>
      )}
      <div className='diggerCard__location'>
        {user.candidate_skills && user.candidate_skills.length > 0 && (
          <p className='location__detail'>
            {/* <LocationIcon /> */}
            <ion-icon name='location-outline' />
            <span>Primary Skill: </span>{user.candidate_skills[0].name}
          </p>
        )}
        {/* <p className='location__detail' style={{ marginLeft: '1rem' }}><MoneyIcon /> Full Time</p> */}
      </div>
      <div className='circle' />
      <div className='circle left' />
    </div>
  )
}
