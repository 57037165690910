import { motion } from 'framer-motion'
import React, { useEffect, useReducer, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Grid } from '../../components/blocks/Grid'
import { List } from '../../components/blocks/List'
import { Pill } from '../../components/blocks/Pill'
import { Section } from '../../components/layout/Section'
import { useJob } from '../../hooks/useJobs'
import { Modal, modalReducer } from '../../components/layout/Modal'
import { Link } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { applyForJob, forceApplyForJob } from '../../hooks/useApi'
import { Alert } from '../../components/blocks/Alert'
import { useAlert } from '../../hooks/useAlert'
import { setEdjs } from '../../hooks/utils/useEdjs'

export const JobDetail = () => {
  // CONSTANTS
  const { jobUid } = useParams()
  const { push } = useHistory()
  const queryClient = useQueryClient()
  const { isActive, message, openAlert } = useAlert()

  // API
  const job = useJob(jobUid)
  console.log(job)
  const { mutateAsync } = useMutation(applyForJob, {
    onSuccess: () => {
      // TODO: Invalidate query
      queryClient.invalidateQueries('job')
      queryClient.invalidateQueries('jobs')
      dispatch({ type: 'close' })
      openAlert('Successfully applied')
    },
    onError: (error) => {
      openAlert(error.message)
      dispatch({ type: 'close' })
      // TODO: Get correct error message from response
    }
  })
  const { mutateAsync: forceApply } = useMutation(forceApplyForJob, {
    onSuccess: () => {
      // TODO: Invalidate query
      queryClient.invalidateQueries('job')
      queryClient.invalidateQueries('jobs')
      dispatch({ type: 'close' })
      openAlert('Successfully applied')
    },
    onError: (error) => {
      // TODO: Get correct error message from response
      openAlert(error.message)
      dispatch({ type: 'close' })
    }
  })

  // STATE
  const [expanded, setExpanded] = useState(false)
  const [state, dispatch] = useReducer(modalReducer, { open: false })

  // EFFECTS
  useEffect(() => {
    if (!job.isLoading && !job.data) {
      push('/')
    }
    // eslint-disable-next-line
  }, [job.isLoading, job.data])

  // METHODS
  const onApply = async (isForced = false) => {
    // openModal()
    const { jobCandidates: candidates, jobRequirements } = job.data
    if (candidates.length > 0 || isForced) {
      if (jobRequirements.length <= 0) {
        if (isForced) {
          await forceApply({ jobUid, jobRequirementCandidateProof: null })
        } else {
          await mutateAsync({ jobUid, jobRequirementCandidateProof: null })
        }
      } else {
        push({
          pathname: `/proof/${jobUid}?forced=true`,
          props: { job: job.data, force: true }
        })
      }
    } else dispatch({ type: 'open' })
  }

  return (
    <>
      <Alert isActive={isActive} message={message} />

      <Modal
        open={state.open}
        onClose={() => dispatch({ type: 'close' })}
        size='small'
        title="You're not matched"
        caption='So you may not be the correct candidate'
      >
        <Link to='/app/profile?profile=skills' className='button button__primary button--full button--above'>Update my skills</Link>
        <button
          className='button button__secondary button--full button--above'
          onClick={() => onApply(true)}
        >
          Apply Anyways
        </button>
        <button
          className='button button__secondary button--full'
          onClick={() => dispatch({ type: 'close' })}
        >
          Cancel
        </button>
      </Modal>
      {!job.isLoading && job.data
        ? (
          <motion.div
            className='col-xs-12 col-sm-4 detail__view'
            initial={{ x: '25vh' }}
            animate={{ x: '0' }}
            exit={{ x: '25vh' }}
          >
            <div
              className='hero'
              style={{ backgroundImage: `url(${job.data.photoUrl})` }}
            >
              <div className='hero__content'>
                {job.data.jobCandidates.length > 0 && job.data.jobCandidates[0].status !== 'matched' && job.data.jobCandidates[0].status !== 'potential' && (
                  // <StatusPill message={job.data.jobCandidates[0].status} />
                  job.data.jobCandidates[0].status === 'applicant'
                    ? (<Pill message='applied' />)
                    : job.data.jobCandidates[0].status === 'shortlist'
                      ? (<Pill type='Success' message='shortlisted' />)
                      : null
                )}
                <div id='scrollContent'>
                  <h3 className='hero__title job'>{job.data.title}</h3>
                  <Link to={`/app/location/${job.data.location.uid}`} style={{ color: 'white', fontSize: '0.75rem' }} className='hero__caption'>{job.data.location.title}</Link>
                </div>
              </div>
              {job.data.jobCandidates.length > 0
                ? (
                  <>
                    {job.data.jobCandidates[0].status === 'matched' || job.data.jobCandidates[0].status === 'potential'
                      ? <button onClick={() => onApply()} id='actionButton' className='hero__floatingBtn button button__primary'>Apply</button>
                      : <button onClick={() => push(`/chat/${job.data.jobCandidates[0].uid}`)} className='hero__floatingBtn button button__primary'>Chat Now</button>}
                  </>
                  )
                : (
                  <button onClick={() => onApply()} id='actionButton' className='hero__floatingBtn button button__primary'>Apply</button>
                  )}
            </div>
            <div className='page__details'>
              <List>
                <Grid>
                  <List.Item color='darkblue' variant='white noMargin'>
                    <List.Caption>Max Salary:</List.Caption>
                    R {job.data.maxSalary}.00
                  </List.Item>
                  <List.Item color='yellow' variant='white'>
                    <List.Caption>Type:</List.Caption>
                    Remote
                  </List.Item>
                </Grid>
              </List>
              <p className='section__title'>Job Description</p>
              <motion.div
                initial={{ height: '16vh', overflow: 'hidden' }}
                animate={expanded ? { height: 'auto', overflow: 'auto' } : { height: '16vh', overflow: 'hidden' }}
                transition={{ duration: 0.3, ease: [0.6, 0.05, -0.01, 0.9] }}
                className={!expanded ? 'description' : 'description expanded'}
              >
                {/* <div dangerouslySetInnerHTML={{ __html: editorRenderer.parse(job.data.descriptionAsJSON) }} /> */}
                <div className='editor' dangerouslySetInnerHTML={{ __html: setEdjs(job.data.descriptionAsJSON.blocks) }} />
              </motion.div>
              <p onClick={() => setExpanded(!expanded)} className='read__more'>{!expanded ? 'Read More' : 'Show Less'}</p>
              <div className='section__title'>Skills</div>
              <List>
                {job.data.jobSkills.map((skill, i) => (
                  <List.Item key={i} variant='border'>
                    <List.Caption>{skill.experience} years experience</List.Caption>
                    {skill.skill.name}
                  </List.Item>
                ))}
              </List>
              {job.data.jobRequirements.length > 0 && job.data.jobRequirements && (
                <>
                  <Section>
                    <Section.Title>Requirements</Section.Title>
                    <Section.Footer>Make sure you have these before you Apply</Section.Footer>
                  </Section>
                  <List>
                    {job.data.jobRequirements.map((req, i) => (
                      <List.Item key={i}>
                        <List.Caption>Requirement</List.Caption>
                        {req.requirement}
                      </List.Item>
                    ))}

                  </List>
                </>
              )}
            </div>
          </motion.div>
          )
        : (
          <div>No job</div>
          )}
    </>
  )
}
