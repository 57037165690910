import { motion } from 'framer-motion'
import React from 'react'

export const Alert = ({ isActive, message, title, icon }) => {
  if (!message) return null
  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={isActive ? { opacity: 1, y: 0 } : { opacity: 0, y: -100 }}
      transition={{ duration: 0.3, ease: [0.6, 0.05, -0.01, 0.9] }}
      className='alert__wrapper'
    >
      <div className='alert__accent' />
      <div className='alert__content'>
        {icon && (<div className='alert__icon'>{icon}</div>)}
        <div className='message__wrapper'>
          <p className='alert__title'>{message?.title}</p>
          <p className='alert__message'>{message?.msg}</p>
        </div>
      </div>
    </motion.div>
  )
}
