import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Link, Route, Switch, useLocation } from 'react-router-dom'
import { Loader } from '../../components/blocks/Loader'
import { PublicJobCard } from '../../components/blocks/PublicJobCard'
import { Container } from '../../components/layout/Container'
import { Row } from '../../components/layout/Row'
import { Section } from '../../components/layout/Section'
import { Tabs } from '../../components/layout/Tabs'
import { usePublicJobs } from '../../hooks/useJobs'
import { useLocalStorage } from '../../hooks/utils/useLocalStorage'
import { PublicJobDetails } from './PublicJobDetails'

export const PublicJobs = () => {
  function useQuery () {
    return new URLSearchParams(useLocation().search)
  }

  // CONSTANTS
  const queryParam = useQuery()

  // STATE
  const [open, setOpen] = useLocalStorage('sidebar', 'hidden')
  const [activeTab, setActiveTab] = useState(() => queryParam.get('type'))

  // API
  const jobsQuery = usePublicJobs()

  const jobClasses = classNames(
    'col-xs-12 job__view',
    {
      'col-sm-8': window.location.pathname.split('/app/')[1],
      'col-sm-12': window.location.pathname.split('/app/')[1] === false
    }
  )

  useEffect(() => {
    if (open && open !== 'hidden') setOpen('hidden')
    // eslint-disable-next-line
  }, [open])
  return (
    <Container>
      {
        !jobsQuery.isLoading
          ? (
            <div className={jobClasses}>
              <Section>
                <Section.Title>Jobs</Section.Title>
                <Section.Footer>Cool things</Section.Footer>
              </Section>
              <Tabs
                name='type'
                onTabChange={setActiveTab}
                active={activeTab}
              >
                <Tabs.Tab value='top'>
                  <p><Link style={{ color: '#4875f3' }} to='/auth/register'>Register for digger</Link> to see your top jobs</p>
                </Tabs.Tab>
                <Tabs.Tab value='recent'>
                  <p><Link style={{ color: '#4875f3' }} to='/auth/register'>Register for digger</Link> to see your recently matched jobs</p>
                </Tabs.Tab>
              </Tabs>

              <Section style={{ marginTop: '1rem' }}>
                <Section.Title>More Jobs</Section.Title>
              </Section>
              {jobsQuery.data && jobsQuery.data.length > 0
                ? (
                  <Row>
                    {jobsQuery.data.map(job => (
                      <PublicJobCard horizontal key={job.uid} job={job} />
                    ))}
                  </Row>
                  )
                : (
                  <div>Sorry, there are no public jobs for you to see</div>
                  )}
            </div>
            )
          : (
            <Loader />
            )
      }
      <Switch>
        <Route path='/public/job/:jobUid' component={PublicJobDetails} />
      </Switch>
    </Container>
  )
}
