import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLocation } from '../../hooks/useLocations'

export const LocationCard = ({ location }) => {
  function useQuery () {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()

  return (
    <NavLink to={query.get('type') ? `/app/location/${location.uid}?type=${query.get('type')}` : `/app/location/${location.uid}`} className='location__card'>
      <img className='location__img' src={location.photoUrl} alt='location' />
      <h3 className='location__title'>{location.title}</h3>
      <p className='location__address'>{location.addressDescription}</p>
    </NavLink>
  )
}
