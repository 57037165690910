import classNames from 'classnames'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export const Tabs = ({ children, style, active = null, name, onTabChange, ...rest }) => {
  return (
    <>
      <ul style={{ ...style }} className='tabs'>
        {React.Children.map(children, (child, i) => {
          return React.cloneElement(child, { active, name, onTabChange, key: i, index: i })
        })}
      </ul>
      <div className='tabs__content'>
        {React.Children.map(children, (child, i) => {
          if (active === child.props.value) return child.props.children
          else return undefined
        })}
      </div>
    </>
  )
}

Tabs.Tab = function Tab ({ children, value, defaultActive, name, active, index, onTabChange, ...rest }) {
  useEffect(() => {
    if (active === null && index === 0) onTabChange(value)
    // eslint-disable-next-line
  }, [active])

  const tabClass = classNames(
    'tab', {
      'tab--active': value === active
    }
  )

  return <Link to={`${window.location.pathname}?${name}=${value}`} onClick={() => onTabChange(value)} className={tabClass}>{value}</Link>
}
