import classNames from 'classnames'
import React from 'react'

export const Container = ({ children, centered, addedClass }) => {
  const containerClass = classNames(
    'container', {
      'container--centered': centered,
      [addedClass]: addedClass
    }
  )

  return (
    <div className={containerClass}>
      {children}
    </div>
  )
}
