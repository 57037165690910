import axios from 'axios'
import { AUTH_HEADER } from './useApi'
import { useQuery, useQueryClient } from 'react-query'

export const useJobs = () => {
  return useQuery(
    'jobs',
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/jobs`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 30,
      refetchOnWindowFocus: true
    }
  )
}

export const usePublicJobs = () => {
  return useQuery(
    'publicJobs',
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/jobs`
    ).then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 30
    }
  )
}

export const useJob = (jobUid) => {
  const queryClient = useQueryClient()

  return useQuery(
    ['job', jobUid],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data.data), {
      initialData: () => {
        if (jobUid) {
          const cachedJobs = queryClient.getQueryData('jobs')
          if (cachedJobs !== undefined && Array.isArray(cachedJobs)) return cachedJobs?.find(x => x.uid === jobUid)
          else if (cachedJobs !== undefined && typeof cachedJobs === 'object') return cachedJobs
        }
      },
      initialStale: true,
      enabled: !!jobUid
    }
  )
}

export const useJobCandidate = (jobCandidateUid) => {
  return useQuery(
    ['jobCandidate', jobCandidateUid],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/jobs/job-candidates/${jobCandidateUid}`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data.data), {
      initialStale: true,
      enabled: !!jobCandidateUid
    }
  )
}

export const usePublicJob = (jobUid) => {
  const queryClient = useQueryClient()

  return useQuery(
    ['publicJobs', jobUid],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}`
    ).then(res => res.data.data), {
      initialData: () => {
        return queryClient.getQueryData('publicJobs')?.find(x => x.uid === jobUid)
      },
      initialStale: true,
      enabled: !!jobUid
    }
  )
}
