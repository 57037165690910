export const edjsHTML = (
  function () {
    const t = {
      delimiter: function () {
        return '<br/>'
      },
      eader: function (t) {
        const e = t.data; return '<h' + e.level + '>' + e.text + '</h' + e.level + '>'
      },
      paragraph: function (t) {
        return '<p>' + t.data.text + '</p>'
      },
      list: function (t) {
        // eslint-disable-next-line
        const e = t.data; const n = e.style === 'unordered' ? 'ul' : 'ol'; let r = ''; return e.items && (r = e.items.map(function (t) { return '<li>' + t + '</li>' }).reduce(function (t, e) { return t + e }, '')), '<' + n + '>' + r + '</' + n + '>'
      },
      image: function (t) {
        const e = t.data; const n = e.caption ? e.caption : 'Image'; return '<img src="' + e.file + '" alt="' + n + '" />'
      },
      quote: function (t) {
        const e = t.data; return '<blockquote>' + e.text + '</blockquote> - ' + e.caption
      },
      code: function (t) {
        return '<pre><code>' + t.data.code + '</code></pre>'
      }
    }
    function e (t) {
      return new Error('[31m The Parser function of type "' + t + '" is not defined. \n\n  Define your custom parser functions as: [34mhttps://github.com/pavittarx/editorjs-html#extend-for-custom-blocks [0m')
      // eslint-disable-next-line
    } return function (n) { return void 0 === n && (n = {}), Object.assign(t, n), { parse: function (n) { return n.blocks.map(function (n) { return t[n.type] ? t[n.type](n) : e(n.type) }) }, parseBlock: function (n) { return t[n.type] ? t[n.type](n) : e(n.type) } } }
  }())
